import { useState, useCallback, useMemo, useEffect } from "react";

import { useDefaultCadastroStyles } from "../components/default-cadastro-styles";

import { ImpressorasList } from "./components/impressoras-list/impressoras-list";
import { Grid } from "@material-ui/core";
import { ImpressorasListSearchProps } from "./components/impressoras-list/impressoras-list-search-props";
import { ImpressorasHeader } from "./components/impressoras-header/impressoras-header";
import { BaixarIcon, NovoIcon } from "views/components/icons";
import { useCadastros, useSessaoAtual } from "services/app";
import { ButtonFabMenu } from "views/components/controles/buttons/button-fab-menu";
import { ButtonFabMenuModel } from "model/app/components/button-fab-menu/button-fab-menu";
import { useThemeQueries } from "views/theme";
import { MenuVerticalIcon } from "views/components/icons/menu-vertical-icon";
import { useTourSaurus } from "services/app/hooks/tour-saurus";
import { EnumTour } from "model/enums/enum-tour";
import { AppEventEnum } from "model/enums/enum-app-event";
import { useEventTools } from "services/app/hooks/events/event-tools";
import { isPlanoFarmaceutico } from "utils/plano-utils";
import { VariaveisAmbiente } from "config";

export const ImpressorasPage = () => {
  const classes = useDefaultCadastroStyles();

  const [openPesquisa, setOpenPesquisa] = useState(false);
  const [searchProps, setSearchProps] = useState<ImpressorasListSearchProps>({ termo: "", status: -1 });
  const { abrirCadastroEquipamentos } = useCadastros();
  const [openCloseMenu, setOpenCloseMenu] = useState<boolean>(false);
  const [finalizouTourImpressoras, setFinalizouTourImpressoras] = useState<boolean>(false)
  const { addHandler, removeHandler } = useEventTools();
  const { theme } = useThemeQueries()
  const { callTour } = useTourSaurus()

  const { plano } = useSessaoAtual();
  const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

  const handleOpenClose = useCallback(() => {
    setOpenCloseMenu(!openCloseMenu);
  }, [openCloseMenu])

  const onClickAdd = useCallback(() => {
    abrirCadastroEquipamentos("", "", true);
  }, [abrirCadastroEquipamentos]);

  const handleDownload = useCallback(() => {
    const fileUrl = VariaveisAmbiente.impressorDownloadUrl;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'impressor.exe';
    link.click();
  }, []);

  const onClickMenuItem = useMemo(() => {
    const itens = new Array<ButtonFabMenuModel>();

    itens.push(new ButtonFabMenuModel(<NovoIcon tipo='BUTTON_FAB' />, 'Adicionar Manualmente', onClickAdd));
    itens.push(new ButtonFabMenuModel(<BaixarIcon tipo='BUTTON_FAB' />, 'Baixar Impressor Windows', handleDownload));


    return itens;
  }, [handleDownload, onClickAdd])

  const acessouFormulario = useCallback((value: any) => {
    setFinalizouTourImpressoras(value.finalizado)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setOpenCloseMenu(true);
      callTour(EnumTour.IMPRESSORAS)
    }, 1000)
    if (finalizouTourImpressoras) {
      setOpenCloseMenu(false)
    }
  }, [callTour, finalizouTourImpressoras, isFarma])

  useEffect(() => {
    addHandler(AppEventEnum.TourFinish, acessouFormulario)
    return () => {
      removeHandler(AppEventEnum.TourFinish, acessouFormulario)
    }
  }, [acessouFormulario, addHandler, isFarma, removeHandler])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ImpressorasHeader
          openPesquisa={openPesquisa}
          setSearchProps={setSearchProps}
          setOpenPesquisa={setOpenPesquisa}
        />
      </Grid>
      <Grid className={classes.list}>
        <ImpressorasList searchProps={searchProps} />
      </Grid>
      <ButtonFabMenu
        open={openCloseMenu}
        onClick={handleOpenClose}
        values={onClickMenuItem}
        color={theme.palette.primary.main}
        colorItens={theme.palette.primary.main}
        icon={<MenuVerticalIcon tipo='BUTTON_FAB' fill={openCloseMenu ? theme.palette.primary.main : '#FFF'} />}
        style={{
          zIndex: 0,
          position: 'fixed',
        }}
        id="tour-Impressoras"
      />
    </Grid>
  );
};

export default ImpressorasPage;
