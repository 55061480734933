
import { useCallback, useMemo, useRef, useState } from 'react';
import { useStyles } from './form-contrato-usuario-styles';
import { useFormContratoUsuarioValidation } from './form-contrato-usuario-validation';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { ContratoUsuarioFormModel } from '../../../../../model/app/forms/master/contrato-usuario-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, isEqual } from 'lodash';
import { Button, Grid } from 'views/design-system';

interface FormNovoContrato extends DefaultFormProps<ContratoUsuarioFormModel> {
  campoApelido: boolean
}
export const FormContratoUsuario = React.forwardRef<
  DefaultFormRefs<ContratoUsuarioFormModel>,
  FormNovoContrato
>(({ campoApelido, ...props }: FormNovoContrato, refs) => {
  const txtEmail = useRef<HTMLInputElement>();
  const apelidoRef = useRef<HTMLInputElement>();
  const { FormContratoUsuarioValidationYup } =
    useFormContratoUsuarioValidation();
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new ContratoUsuarioFormModel('', '', '', ''),
  });
  const { isMobile } = useThemeQueries();
  const [emailDigitadoAnteriormente, setEmailDigitadoAnteriormente] = useState<string>('')

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue
  } = useForm<ContratoUsuarioFormModel>({
    defaultValues: { ...model.modeloForm },
    resolver: yupResolver(FormContratoUsuarioValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });
  const carregando = useMemo(() => {
    return (props.loading && props.showLoading) 
  }, [props.loading, props.showLoading])
  
  const onSubmit = (form: ContratoUsuarioFormModel) => {
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: async () => {
      await handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new ContratoUsuarioFormModel('', '', '', '') });
      reset();
      if (!isMobile) {
        txtEmail?.current?.focus();
      }
    },
    fillForm: (model: ContratoUsuarioFormModel) => {
      setModel({ modeloForm: model });
      reset({
        ...model,
      });
      if (!isMobile) {
        txtEmail?.current?.focus();
      }
      if(campoApelido){
        apelidoRef.current?.focus();
        setValue('apelido', "")
      }
    },
  }));

  const apelidoDeAcordoComEmail = useCallback(async () => {
    const emailCompleto = getValues('email')
    if (!isEmpty(emailCompleto) && isEqual(emailCompleto, emailDigitadoAnteriormente)) {
      return
    }
    setEmailDigitadoAnteriormente(emailCompleto)
    const caracterDeCorte = emailCompleto.split('@')
    const apelido = caracterDeCorte[0]
    setValue('apelido', apelido)

  }, [emailDigitadoAnteriormente, getValues, setValue])
 
  return (
    <>
      <div className={utilClasses.formContainer}>
        {carregando ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="EMAIL"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    inputRef={txtEmail}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="E-mail"
                    placeholder="usuario@empresa.com.br"
                    error={Boolean(errors.email && errors.email.message)}
                    helperText={
                      touchedFields.email || errors.email
                        ? errors.email?.message
                        : undefined
                    }
                    {...field}
                    onBlur={() => {
                      apelidoDeAcordoComEmail()
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="saudacao"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    disabled={props.loading}
                    fullWidth={true}
                    showStartAdornment={true}
                    allowSubmit={false}
                    autoComplete={'off'}
                    label="Informe seu Nome"
                    placeholder="Ex: João da Silva"
                    error={Boolean(errors.saudacao && errors.saudacao.message)}
                    helperText={
                      touchedFields.saudacao || errors.saudacao
                        ? errors.saudacao?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            {campoApelido &&
              <Grid item xs={12}>
                <Controller
                  name="apelido"
                  control={control}
                  render={({ field }) => (
                    <TextFieldSaurus
                      tipo="TEXTO"
                      disabled={props.loading}
                      fullWidth={true}
                      showStartAdornment={true}
                      allowSubmit={false}
                      autoComplete={'off'}
                      inputRef={apelidoRef}
                      label="Apelido (Nome de usuário)"
                      placeholder="Ex: joao11"
                      error={Boolean(errors.apelido && errors.apelido.message)}
                      helperText={
                        touchedFields.apelido || errors.apelido
                          ? errors.apelido?.message
                          : undefined
                      }
                      {...field}
                    />
                  )}
                />
              </Grid>}
            <Grid item xs={12} sm={6}>
              <Controller
                name="senha"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="PASSWORD"
                    allowSubmit={false}
                    disabled={props.loading}
                    showStartAdornment={true}
                    fullWidth={true}
                    autoComplete={'off'}
                    label="Senha"
                    placeholder="(6-32 caracteres)"
                    error={Boolean(errors.senha && errors.senha.message)}
                    helperText={
                      touchedFields.senha || errors.senha
                        ? errors.senha?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="confirmar"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    allowSubmit={true}
                    tipo="PASSWORD"
                    disabled={props.loading}
                    showStartAdornment={true}
                    fullWidth={true}
                    autoComplete={'off'}
                    label="Confirmação da Senha"
                    placeholder="(6-32 caracteres)"
                    error={Boolean(
                      errors.confirmar && errors.confirmar.message,
                    )}
                    helperText={
                      touchedFields.confirmar || errors.confirmar
                        ? errors.confirmar?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
