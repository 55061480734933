import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultFormRefs } from 'views/components/form/utils/form-default-props';
import {
  AvancarIcon,
  VoltarIcon,
  OkIcon,
  ConfiguracaoIcon,
  RelogioIcon,
  NovoUsuarioIcon,
  UserIcon,
  SetorProcessosAddIcon,
  PessoasIcon,
} from 'views/components/icons';
import { useFormStepper } from 'views/components/form-stepper';
import { EnumTelasConfigIniciais } from 'model/enums/enum-tela-configuracoes-iniciais';
import { FormStep } from 'model/app';
import { GestaoStorageKeys, useCadastros, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { HorarioModel } from 'model/api/gestao/horarios-funcionamento/horario-model';
import { CardapioIcon } from 'views/components/icons/cardapio-icon';
import { NfceIcon } from 'views/components/icons/nfce-icon';
import { ConfigNfceFormModel } from 'model/app/forms/master/configuracoes-nfce-form-model';
import { FormConfigNfce } from 'views/components/form/master/configuracoes-nfce/form-configuracoes-nfce';
import { isEmpty } from 'lodash';
import { FormMesasEcomandas } from 'views/components/form/mesas-e-comandas/form-mesas-e-comandas/form-mesas-e-comandas';
import { ConfigInicialMesasEcomandasModel } from 'model/api/gestao/config-inicial/config-inicial-mesas-e-comandas-model';
import { SegmentoModel } from 'model/api/gestao/segmento/segmento-model';
import { ConfigInicialCardapioModel } from 'model/api/gestao/config-inicial/config-inicial-cardapio-model';
import { ConfigInicialPerfil } from 'model/api/gestao/config-inicial/config-inicial-perfil-de-acesso';
import { useStyles } from './use-config-inicial-styles';
import { FormConfigInicialCardapio } from 'views/components/form/cardapio/form-config-inicial-cardapio';
import { ConfigInicialPerfis } from 'model/api/gestao/config-inicial/config-inicial-permissoes';
import { useThemeQueries } from 'views/theme';
import { useGetPlanos } from 'data/api/gestao/plano/get-planos';
import { PlanoModel, PropOpcoes } from 'model/api/gestao/plano/plano-model';
import { LeituraCaixaIcon } from 'views/components/icons/leitura-caixa-icon';
import { FormSetoresConfigInicial } from 'views/components/form/setor/form-setor-config-inicial/form-setor-config-inicial';
import { SetorCadastroFormModel } from 'model/app/forms/setor/setor-cadastro-form-model';
import { PessoaIcon } from 'views/components/icons/pessoa-icon';
import { ComboIcon } from 'views/components/icons/combo-icon';
import { CarrinhoIcon } from 'views/components/icons/carrinho-icon';
import { TalheresIcon } from 'views/components/icons/talheres-icon';
import { TacaIcon } from 'views/components/icons/taca-icon';
import { RamoIcon } from 'views/components/icons/ramo-icon';
import { FormEstabelecimentoHorariosConfigInicial } from 'views/components/form/horarios-funcionamento/form-estabelecimento-horarios-config-inicial/form-estabelecimento-horarios-config-inicial';
import { AccordionSaurus } from 'views/components/accordions/accordion-saurus/accordion-saurus';
import { CardPlanoConfigInicial } from 'views/components/cards/card-plano-config-inicial/card-plano-config-inicial';
import { usePostConfigInicial } from 'data/api/gestao/config-inicial/post-config-inicial';
import { CarouselSaurus } from 'views/components/carousel-saurus';
import { CloseIconCircle } from 'views/components/icons/close-icon-circle';
import { QuestionarioEtapa } from './questionario-etapa/questionario-etapa';
import { SwitchSaurus } from 'views/components/controles';
import { UpgradeIcon } from 'views/components/icons/upgrade-icon';
import { MesaIcon } from 'views/components/icons/mesa-icon';
import { CircularLoading } from 'views/components/utils';
import { ConfigInicialModel } from 'model/api/gestao/config-inicial/config-inicial-model';
import { TutorialPostModel, TutorialResModel } from 'model/api/gestao/tutorial/tutorial-post-model';
import { usePostTutorial } from 'data/api/gestao/tutorial/post-tutorial';
import { sistemaId } from 'config';
import { EnumTour } from 'model/enums/enum-tour';
import { useGetTutorial } from 'data/api/gestao/tutorial/get-tutorial';
import { EnumFluxoTutorial } from 'model/enums/enum-fluxo-tutorial';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';

const perfilGerente = [
  {

    "codigo": 2000,
    "valor": "true"
  },
  {

    "codigo": 2001,
    "valor": "true"
  },
  {

    "codigo": 2002,
    "valor": "true"
  },
  {

    "codigo": 2004,
    "valor": "true"
  },
  {

    "codigo": 2005,
    "valor": "true"
  },
  {

    "codigo": 2007,
    "valor": "true"
  },
  {

    "codigo": 2008,
    "valor": "true"
  },
  {

    "codigo": 2009,
    "valor": "false"
  },
  {

    "codigo": 2010,
    "valor": "true"
  },
  {

    "codigo": 2011,
    "valor": "true"
  },
  {

    "codigo": 2012,
    "valor": "false"
  },
  {

    "codigo": 2013,
    "valor": "false"
  },
  {

    "codigo": 2015,
    "valor": "true"
  },
  {

    "codigo": 2016,
    "valor": "false"
  },
  {

    "codigo": 2017,
    "valor": "false"
  },

  {

    "codigo": 530104,
    "valor": "true"
  },
  {

    "codigo": 530105,
    "valor": "true"
  },
  {

    "codigo": 530106,
    "valor": 10
  },
  {

    "codigo": 530107,
    "valor": 10
  },
  {

    "codigo": 540113,
    "valor": "true"
  },
  {

    "codigo": 540114,
    "valor": "true"
  },

  {

    "codigo": 5000,
    "valor": "true"
  },
  {

    "codigo": 5001,
    "valor": "true"
  },
  {

    "codigo": 5002,
    "valor": "true"
  },
  {

    "codigo": 5003,
    "valor": "false"
  },
  {

    "codigo": 5004,
    "valor": "true"
  }
]



const perfilCaixa =
  [

    {

      "codigo": 2000,
      "valor": "false"
    },
    {

      "codigo": 2001,
      "valor": "false"
    },
    {

      "codigo": 2002,
      "valor": "false"
    },
    {

      "codigo": 2004,
      "valor": "false"
    },
    {

      "codigo": 2005,
      "valor": "false"
    },
    {

      "codigo": 2007,
      "valor": "false"
    },
    {

      "codigo": 2008,
      "valor": "false"
    },
    {

      "codigo": 2009,
      "valor": "false"
    },
    {

      "codigo": 2010,
      "valor": "false"
    },
    {

      "codigo": 2011,
      "valor": "false"
    },
    {

      "codigo": 2012,
      "valor": "false"
    },
    {

      "codigo": 2013,
      "valor": "false"
    },
    {

      "codigo": 2015,
      "valor": "false"
    },
    {

      "codigo": 2016,
      "valor": "false"
    },
    {

      "codigo": 2017,
      "valor": "false"
    },
    {

      "codigo": 530104,
      "valor": "false"
    },
    {

      "codigo": 530105,
      "valor": "false"
    },
    {

      "codigo": 530106,
      "valor": 0
    },
    {

      "codigo": 530107,
      "valor": 0
    },
    {

      "codigo": 540113,
      "valor": "false"
    },
    {

      "codigo": 540114,
      "valor": "false"
    },

    {

      "codigo": 5000,
      "valor": "false"
    },
    {

      "codigo": 5001,
      "valor": "false"
    },
    {

      "codigo": 5002,
      "valor": "false"
    },
    {

      "codigo": 5003,
      "valor": "false"
    },
    {

      "codigo": 5004,
      "valor": "false"
    }
  ]

interface ModuloProps {
  configuracoesRealizadas: boolean,
  modulos: PropOpcoes,
  codigo: number,
  descricao: string
}

interface CarouselProps {
  children: HTMLCollection
}

export const useConfigInicial = () => {
  const { currentStep, nextStep, prevStep, skipStep } = useFormStepper(11);
  const { getEmpresaSelecionada, refreshPlanoUsuario } = useSessaoAtual();
  const { getRegistro } = useGestaoStorage()
  const { getPlanos } = useGetPlanos()

  const user = getRegistro(GestaoStorageKeys.MeuUsuario, false)
  const userName = user.nome
  //states
  const [upgradesNecessarios, setUpgradesNecessarios] = useState<string[]>([])
  const [configuracoesRealizadas, setConfiguracoesRealizadas] = useState<{
    mesasEcomandas: boolean
    setores: boolean
    perfil: boolean
    cardapio: boolean
    nfce: boolean
    horarios: boolean
    planoAtual: boolean

  }>({
    mesasEcomandas: false,
    setores: false,
    perfil: false,
    cardapio: false,
    nfce: false,
    horarios: false,
    planoAtual: false
  })
  const [planoOfertado, setPlanoOfertado] = useState<PlanoModel | null>(null)
  const idContrato = getEmpresaSelecionada()?.ContratoId || ''
  const idEmpresa = getEmpresaSelecionada()?.Id || ''
  const [formPlanoAtual, setFormPlanoAtual] = useState<ConfigInicialModel>(new ConfigInicialModel())
  const [formPlanoUpgrade, setFormPlanoUpgrade] = useState<ConfigInicialModel>(new ConfigInicialModel())
  const [segmentoSelecionado, setSegmentoSelecionado] = useState<string>("")
  const [perfisSelecionados, setPerfisSelecionados] = useState<Array<number>>([])
  const [planos, setPlanos] = useState<PlanoModel[]>([])
  const [ramoSelecionado, setRamoSelecionado] = useState<number>()
  const { postConfigInicial, carregando } = usePostConfigInicial()
  const { postTutorial } = usePostTutorial()
  const { getTutorial } = useGetTutorial()
  const classes = useStyles()
  const { theme, isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus()
  const { fecharConfiguracoesIniciais } = useCadastros()
  const [dadosWizard, setDadosWizard] = useState<TutorialResModel | null>()
  const { callEvent } = useEventTools()
  const contratoId = getEmpresaSelecionada()?.ContratoId ?? ""

  //Refs de formulário
  const refConfigSegmentoForm =
    useRef<DefaultFormRefs<SegmentoModel>>(null)
  const refConfigMesasEcomandasForm =
    useRef<DefaultFormRefs<ConfigInicialMesasEcomandasModel>>(null);
  const refConfigSetoresForm =
    useRef<DefaultFormRefs<SetorCadastroFormModel[]>>(null);
  const refConfigCardapioForm =
    useRef<DefaultFormRefs<ConfigInicialCardapioModel>>(null);
  const refConfigHorariosDeFuncionamentoForm =
    useRef<DefaultFormRefs<HorarioModel>>(null);
  const refConfigNfceForm =
    useRef<DefaultFormRefs<ConfigNfceFormModel>>(null)



  //-----------------------------------------refs para os forms no  vai e volta do stepper
  const carouselRef = useRef<CarouselProps
  >();
  const refConfigSegmento = useRef<SegmentoModel>(
    new SegmentoModel(),
  );
  const refConfigMesasEcomandas = useRef<ConfigInicialMesasEcomandasModel>(
    new ConfigInicialMesasEcomandasModel(),
  );
  const refConfigSetores = useRef<SetorCadastroFormModel[]>(
    [],
  );
  const refConfigCardapio = useRef<ConfigInicialCardapioModel>(
    new ConfigInicialCardapioModel(),
  );
  const refConfigHorariosDeFuncionamento = useRef<HorarioModel>(
    new HorarioModel(),
  );
  const refConfigPerfis = useRef<ConfigInicialPerfis>(
    new ConfigInicialPerfis(),
  );
  const refConfigNfce = useRef<ConfigNfceFormModel>(
    new ConfigNfceFormModel(),
  );

  const segmentoEscolhido = useCallback((seg: string, index: number, ref?: any) => {
    try {
      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        })
      }
      setSegmentoSelecionado(seg)
      setRamoSelecionado(index)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [showToast])

  const planoAtual = useMemo(() => getRegistro(GestaoStorageKeys.Plano, false), [getRegistro])
  const planoResumo: PlanoModel = useMemo(() => planoAtual.plano, [planoAtual.plano])
  const planoAtualOpcoes = useMemo(() => planoAtual.plano.opcoes, [planoAtual.plano.opcoes])

  const pesquisarModulo = useCallback((codigo: number) => {
    try {
      const objPesquisado = planoAtualOpcoes?.find((x: any) => x.codigo === codigo)
      return objPesquisado
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [planoAtualOpcoes, showToast])




  const moduloNfce: PropOpcoes = useMemo(() => pesquisarModulo(EnumPlanoOpcoes.Fiscal), [pesquisarModulo])
  const moduloOperacaoPedido: PropOpcoes = useMemo(() => pesquisarModulo(EnumPlanoOpcoes.OperacaoPedido), [pesquisarModulo])
  const moduloAcessosPermissoes: PropOpcoes = useMemo(() => pesquisarModulo(EnumPlanoOpcoes.ControleDeAcesso), [pesquisarModulo])
  const moduloCardapio: PropOpcoes = useMemo(() => pesquisarModulo(EnumPlanoOpcoes.VitrineDigital), [pesquisarModulo])


  const modulos: ModuloProps[] = useMemo(() => {
    const modulos =
      [
        {
          configuracoesRealizadas: configuracoesRealizadas.nfce,
          modulos: moduloNfce,
          codigo: EnumPlanoOpcoes.Fiscal,
          descricao: 'Emissão de NFC-E'
        },
        {
          configuracoesRealizadas: configuracoesRealizadas.mesasEcomandas,
          modulos: moduloOperacaoPedido,
          codigo: EnumPlanoOpcoes.OperacaoPedido,
          descricao: 'Mesas e Comandas'
        },
        {
          configuracoesRealizadas: configuracoesRealizadas.perfil,
          modulos: moduloAcessosPermissoes,
          codigo: EnumPlanoOpcoes.ControleDeAcesso,
          descricao: 'Perfil'
        },
        {
          configuracoesRealizadas: configuracoesRealizadas.cardapio,
          modulos: moduloCardapio,
          codigo: EnumPlanoOpcoes.VitrineDigital,
          descricao: 'Cardápio'
        }
      ]
    return modulos
  }, [configuracoesRealizadas.cardapio, configuracoesRealizadas.mesasEcomandas, configuracoesRealizadas.nfce, configuracoesRealizadas.perfil, moduloAcessosPermissoes, moduloCardapio, moduloNfce, moduloOperacaoPedido])


  const possuiModulo = (modulo: string) => {
    const dado = modulo
    if (dado !== "0") {
      return true
    } else {
      return false
    }
  }

  const getPlanosWrapper = useCallback(async () => {
    try {
      const res = await getPlanos()
      if (res.erro) {
        throw (res.erro)
      }
      setPlanos(res.resultado?.data)
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [getPlanos, showToast])


  useEffect(() => {
    getPlanosWrapper()
  }, [getPlanosWrapper])



  const planoIdeal = (planos: Array<PlanoModel>) => {
    let melhorOpcao = planos.reduce((menor: PlanoModel, atual: PlanoModel) => {
      if (atual.valor < menor.valor) {
        return atual
      } else { return menor };
    }, planos[0]);
    return (melhorOpcao)
  }


  const ofertarPlano = useCallback(async (modulos: ModuloProps[]) => {
    try {
      let modulosParaPesquisa: PropOpcoes[] = []
      modulos.forEach(modulo => {
        if (!possuiModulo(modulo.modulos.valor) && modulo.configuracoesRealizadas) {
          modulosParaPesquisa = [...modulosParaPesquisa, modulo.modulos]
        }
      })
      const planosC = planos?.filter(plano => modulosParaPesquisa.every(filtro => plano.opcoes.some(opcao => opcao.codigo === filtro.codigo && opcao.valor !== '0')))
      const planoPersonalizado = planoIdeal(planosC)
      setUpgradesNecessarios(modulosParaPesquisa.map((modulo) => modulo.nome))
      setPlanoOfertado(planoPersonalizado)
    }
    catch (err: any) {
      showToast('error', err.message)
    }
  }, [planos, showToast])

  const getResumo = useCallback(async () => {
    try {
      await ofertarPlano(modulos)
      let planoParaUpgrade = new ConfigInicialModel()
      planoParaUpgrade.plano = planoOfertado!
      if (planoParaUpgrade.plano) {
        planoParaUpgrade.plano.dValidade = planoResumo.dValidade
      }
      planoParaUpgrade.mesasEcomandas = refConfigMesasEcomandas.current
      planoParaUpgrade.setores = refConfigSetores.current
      planoParaUpgrade.cardapio = refConfigCardapio.current

      planoParaUpgrade.horario = refConfigHorariosDeFuncionamento.current
      planoParaUpgrade.perfis = refConfigPerfis.current
      planoParaUpgrade.nfce = refConfigNfce.current

      setFormPlanoUpgrade(planoParaUpgrade)

      let planoParaManter = new ConfigInicialModel()
      planoParaManter.plano = planoResumo

      planoParaManter.horario = refConfigHorariosDeFuncionamento.current
      planoParaManter.setores = refConfigSetores.current
      if (possuiModulo(moduloAcessosPermissoes.valor)) {
        planoParaManter.perfis = refConfigPerfis.current
      }
      if (possuiModulo(moduloOperacaoPedido.valor)) {
        planoParaManter.mesasEcomandas = refConfigMesasEcomandas.current
      }
      if (possuiModulo(moduloCardapio.valor)) {
        planoParaManter.cardapio = refConfigCardapio.current
      }
      if (possuiModulo(moduloNfce.valor)) {
        planoParaManter.nfce = refConfigNfce.current
      }

      setFormPlanoAtual(planoParaManter)
    } catch (err: any) {
      showToast('error', err.message)
    }

  }, [moduloAcessosPermissoes.valor, moduloCardapio.valor, moduloNfce.valor, moduloOperacaoPedido.valor, modulos, ofertarPlano, planoOfertado, planoResumo, showToast])

  const postTours = useCallback(async (tutorialStatus: TutorialPostModel) => {
    try {
      const res = await postTutorial(tutorialStatus)
      if (res.erro) throw res.erro
      return res.resultado?.data
    } catch (err: any) {
      showToast('error', err.message)
    }

  }, [postTutorial, showToast])

  const getInfoWizard = useCallback(async () => {
    try {
      const res = await getTutorial(contratoId, sistemaId)
      if (res.erro) throw res.erro
      return res.resultado?.data
    } catch (err: any) {
      showToast('error', err.message)
    }
  }, [contratoId, getTutorial, showToast])

  const infoWizard = useCallback(async () => {
    const iniciarWizard = await getInfoWizard() as TutorialResModel[]
    const wizard = iniciarWizard.find((x) => x.tipo === EnumTour.WIZARD)
    setDadosWizard(wizard)

  }, [getInfoWizard])

  useEffect(() => {
    infoWizard()
  }, [infoWizard])

  const dadosTour = useMemo(() => {
    const dados = new TutorialPostModel(dadosWizard?.id, contratoId, EnumTour.WIZARD, true, EnumFluxoTutorial.WIZARD)
    return dados
  }, [contratoId, dadosWizard?.id])

  //_____________________get que é chamado no switch para para renderizar formulários

  const validarConfiguracoes = useCallback((
    key: "mesasEcomandas" | "setores" | "perfil" | "cardapio" | "nfce" | "horarios" | "planoAtual",
    utiliza: boolean) => {
    setConfiguracoesRealizadas((prev) => {
      return {
        ...prev,
        [key]: utiliza
      }
    })
  }, [])

  const retTelaIndex = useCallback(
    (index: number): EnumTelasConfigIniciais | undefined => {
      if (segmentoSelecionado === 'Varejo Geral') {
        switch (index) {
          case 0:
            return EnumTelasConfigIniciais.Saudacao;
          case 1:
            return EnumTelasConfigIniciais.Segmento;
          case 2:
            return EnumTelasConfigIniciais.Cardapio;
          case 3:
            return EnumTelasConfigIniciais.Perfil;
          case 4:
            return EnumTelasConfigIniciais.NFCE;
          case 5:
            return EnumTelasConfigIniciais.ResumoDasConfiguracoes;
        }
      } else {
        switch (index) {
          case 0:
            return EnumTelasConfigIniciais.Saudacao;
          case 1:
            return EnumTelasConfigIniciais.Segmento;
          case 2:
            return EnumTelasConfigIniciais.MesasEcomandas;
          case 3:
            return EnumTelasConfigIniciais.Setores;
          case 4:
            return EnumTelasConfigIniciais.Cardapio;
          case 5:
            return EnumTelasConfigIniciais.HorarioDeFuncionamento;
          case 6:
            return EnumTelasConfigIniciais.Perfil;
          case 7:
            return EnumTelasConfigIniciais.NFCE;
          case 8:
            return EnumTelasConfigIniciais.ResumoDasConfiguracoes;
          case 9:
            return EnumTelasConfigIniciais.Config;
        }
      }

    }, [segmentoSelecionado])

  const avancarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelasConfigIniciais.Saudacao:
        nextStep()
        break;
      case EnumTelasConfigIniciais.Segmento:
        refConfigSegmentoForm.current?.submitForm(
        );

        break;
      case EnumTelasConfigIniciais.MesasEcomandas:
        refConfigMesasEcomandasForm.current?.submitForm(
        );
        break;
      case EnumTelasConfigIniciais.Setores:
        refConfigSetoresForm.current?.submitForm(
        );

        break;
      case EnumTelasConfigIniciais.Cardapio:
        refConfigCardapioForm.current?.submitForm(
        );

        break;
      case EnumTelasConfigIniciais.HorarioDeFuncionamento:
        refConfigHorariosDeFuncionamentoForm.current?.submitForm(
        );

        break;
      case EnumTelasConfigIniciais.Perfil:
        nextStep();

        break;
      case EnumTelasConfigIniciais.NFCE:

        refConfigNfceForm.current?.submitForm(true);
        break;

    }
  }, [currentStep, nextStep, retTelaIndex]);


  const voltarStep = useCallback(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelasConfigIniciais.Saudacao:
        prevStep()
        break;
      case EnumTelasConfigIniciais.Segmento:
        prevStep()
        break;
      case EnumTelasConfigIniciais.MesasEcomandas:
        validarConfiguracoes('mesasEcomandas', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.Setores:
        validarConfiguracoes('mesasEcomandas', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.Cardapio:
        validarConfiguracoes('setores', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.HorarioDeFuncionamento:
        validarConfiguracoes('cardapio', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.Perfil:
        validarConfiguracoes('horarios', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.NFCE:
        validarConfiguracoes('perfil', false)
        prevStep();
        break;
      case EnumTelasConfigIniciais.ResumoDasConfiguracoes:
        validarConfiguracoes('nfce', false)
        prevStep();
    }
  }, [currentStep, prevStep, retTelaIndex, validarConfiguracoes]);

  const getSegmentos = useCallback((): JSX.Element => {
    const segmentos = [{
      nome: 'Lanchonete',
      descricao: 'Estabelecimento comercial especializado em lanches e outros alimentos rápidos.',
      icone: <ComboIcon tipo={isMobile ? "MODAL_HEADER" : "BUTTON"} fill={theme.palette.primary.main} />,

    }, {
      nome: 'Restaurante',
      descricao: 'Estabelecimento comercial destinado ao preparo e comércio de refeições.',
      icone: <TalheresIcon tipo={isMobile ? "MODAL_HEADER" : "BUTTON"} fill={theme.palette.primary.main} />,

    }, {
      nome: 'Adega',
      descricao: 'Estabelecimento comercial que comercializa bebidas em geral.',
      icone: <TacaIcon tipo={isMobile ? "MODAL_HEADER" : "BUTTON"} fill={theme.palette.primary.main} />,

    },
    {
      nome: 'Varejo Geral',
      descricao: 'Estabelecimento comercial para venda de produtos para o consumidor final. Exemplo: mercados e açougues.',
      icone: <CarrinhoIcon tipo={isMobile ? "MODAL_HEADER" : "BUTTON"} fill={theme.palette.primary.main} />

    }]


    return (
      <>
        <Grid container justifyContent='space-between' className={classes.containerRamo} >

          <Grid container className={classes.contentRamo} xs={12}>

            {isMobile ?
              <Grid item xs={12} className={classes.slider} innerRef={carouselRef}>
                {segmentos?.map((segmento, index) =>
                  <Grid key={`segmento-${index}-carousel`} xs={12} justifyContent='center' className={classes.cardRamo}>
                    <Button onClick={() => segmentoEscolhido(segmento.nome, index, (carouselRef.current?.children[index]) ?? [])} className={ramoSelecionado === index ? classes.segmentoBotaoSelecionado : classes.segmentoBotao}>
                      <Grid className={classes.segmentoInfo}>
                        <Grid className={classes.botaoRamo}>
                          <Grid alignItems='flex-start' className={classes.iconeBotao} spacing={2}>
                            {segmento.icone}
                          </Grid>
                          <Typography variant={isMobile ? 'h5' : 'button'}>{segmento.nome}</Typography>
                        </Grid>
                        <Typography variant='body1'>{segmento.descricao}</Typography>
                      </Grid>
                    </Button>
                  </Grid>
                )}
              </Grid>
              :

              segmentos?.map((segmento, index) =>
                <Grid item key={`segmento-${index}-desktop`} xs={3} className={classes.cardRamo}>
                  <Button onClick={() => segmentoEscolhido(segmento.nome, index)} variant="outlined" className={ramoSelecionado === index ? classes.segmentoBotaoSelecionado : classes.segmentoBotao}>
                    <Grid className={classes.segmentoInfo}>
                      <Grid className={classes.botaoRamo}>
                        {segmento.icone}
                        <Typography variant='button'>{segmento.nome}</Typography>
                      </Grid>
                      <Typography variant="caption">{segmento.descricao}</Typography>
                    </Grid>
                  </Button>
                </Grid>
              )
            }
            {segmentoSelecionado &&
              <Grid item className={classes.itemRamo}>
                <Grid xs={12} item className={classes.textoRamoSelecionado}>
                  <Typography variant='subtitle1'><strong>Você selecionou o ramo </strong><span className={classes.descricaoDestaque}>{segmentoSelecionado}</span>.</Typography>
                </Grid>
              </Grid>}
          </Grid>

          {segmentoSelecionado &&
            <Grid item className={classes.itemRamo}>

              <Grid item xs={12} alignContent='flex-end'>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  className={classes.stepButtons}
                  onClick={() => {
                    if (segmentoSelecionado)
                      validarConfiguracoes('mesasEcomandas', false)
                    validarConfiguracoes('cardapio', false)
                    nextStep()
                  }}>
                  <AvancarIcon tipo="BUTTON_PRIMARY" />
                  Próximo Passo</Button>
              </Grid>
            </Grid>
          }
        </Grid>
      </>
    )
  }, [classes.botaoRamo, classes.cardRamo, classes.containerRamo, classes.contentRamo, classes.descricaoDestaque, classes.iconeBotao, classes.itemRamo, classes.segmentoBotao, classes.segmentoBotaoSelecionado, classes.segmentoInfo, classes.slider, classes.stepButtons, classes.textoRamoSelecionado, isMobile, nextStep, ramoSelecionado, segmentoEscolhido, segmentoSelecionado, theme.palette.primary.main, validarConfiguracoes]);

  const naoUtilizaModulo = useCallback((key: "mesasEcomandas" | "setores" | "perfil" | "cardapio" | "nfce" | "horarios"
  ) => {
    validarConfiguracoes(key, false)
    nextStep()
  }, [nextStep, validarConfiguracoes])

  const getMesasEcomandas = useCallback((): JSX.Element => {
    const handleSubmitFormMesasEcomandas = async (
      modelo: ConfigInicialMesasEcomandasModel,
    ) => {
      refConfigMesasEcomandas.current = modelo
      nextStep();
    };

    return (
      <Grid xs={12} md={8} container justifyContent='space-between'>

        <Grid className={classes.conteudoDeEtapa} container >
          {configuracoesRealizadas?.mesasEcomandas ?

            <FormMesasEcomandas
              showLoading={false}
              ref={refConfigMesasEcomandasForm}
              model={refConfigMesasEcomandas.current}
              loading={false}
              onSubmit={handleSubmitFormMesasEcomandas}
            />


            :
            <QuestionarioEtapa
              pergunta='Seu estabelecimento utiliza o serviço de mesas e/ou comandas?'
              btnVoltar={() => {
                voltarStep()
              }}
              funcaoBotao1={() => { naoUtilizaModulo('mesasEcomandas') }}
              funcaoBotao2={() => { validarConfiguracoes("mesasEcomandas", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />
          }
        </Grid>
      </Grid>

    );
  }, [classes.conteudoDeEtapa, configuracoesRealizadas?.mesasEcomandas, naoUtilizaModulo, nextStep, validarConfiguracoes, voltarStep]);



  const getSetores = useCallback((): JSX.Element => {
    const handleSubmitFormSetores = async (
      modelo: SetorCadastroFormModel[],
    ) => {

      refConfigSetores.current = modelo;
      nextStep();
    };

    return (
      <Grid xs={12} md={8} container justifyContent='space-between'>
        <Grid className={classes.conteudoDeEtapa} container >
          {configuracoesRealizadas?.setores ? (
            <FormSetoresConfigInicial
              showLoading={false}
              ref={refConfigSetoresForm}
              loading={false}
              onSubmit={handleSubmitFormSetores}
            />
          ) : (
            <QuestionarioEtapa
              pergunta='Seu estabelecimento utiliza o serviço de setores?'
              btnVoltar={voltarStep}
              funcaoBotao1={() => { naoUtilizaModulo('setores') }}
              funcaoBotao2={() => { validarConfiguracoes("setores", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />
          )}
        </Grid >
      </Grid >
    );
  }, [classes.conteudoDeEtapa, configuracoesRealizadas?.setores, naoUtilizaModulo, nextStep, validarConfiguracoes, voltarStep]);

  const getCardapio = useCallback((): JSX.Element => {
    const handleSubmitFormCardapio = async (modelo: ConfigInicialCardapioModel) => {
      try {
        if (isEmpty(modelo.primaryMain)) {
          modelo.primaryMain = '#000000'
        }
        modelo.empresaId = idEmpresa
        const cores = theme.palette.augmentColor({
          '500': modelo.primaryMain,
        });
        if (modelo.type === 0) {
          modelo.secondaryMain = cores.light
          modelo.textPrimary = '#000000'
        } else if (modelo.type === 1) {
          modelo.textPrimary = '#FFFFFF'
          modelo.secondaryMain = cores.dark
        } else {
          modelo.type = 0
        }

        refConfigCardapio.current = modelo
        nextStep()
      } catch (err: any) {
        showToast('error', err.message)
      }
    }

    return (
      <Grid xs={12} md={8} container justifyContent='center'>
        <Grid className={classes.conteudoDeEtapa} container>
          {configuracoesRealizadas?.cardapio ?
            <FormConfigInicialCardapio
              showLoading={false}
              ref={refConfigCardapioForm}
              loading={false}
              onSubmit={handleSubmitFormCardapio}
            />
            :
            <QuestionarioEtapa
              pergunta='Seu estabelecimento utiliza o serviço de cardápio digital?'
              btnVoltar={voltarStep}
              funcaoBotao1={() => { naoUtilizaModulo('cardapio') }}
              funcaoBotao2={() => { validarConfiguracoes("cardapio", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />
          }
        </Grid>
      </Grid>

    );
  }, [classes.conteudoDeEtapa, configuracoesRealizadas?.cardapio, idEmpresa, naoUtilizaModulo, nextStep, showToast, theme.palette, validarConfiguracoes, voltarStep]);


  const getFormHorariosDeFuncionamento = useCallback((): JSX.Element => {
    const handleSubmitFormHorarios = (modelo: HorarioModel) => {
      try {
        refConfigHorariosDeFuncionamento.current = modelo;
        if (refConfigHorariosDeFuncionamento.current?.empresaHorarioDias !== null) {
          modelo.contratoId = idContrato
          modelo.empresaId = idEmpresa
        }
        if (refConfigHorariosDeFuncionamento.current?.empresaHorarioDias.length > 0) {
          if (refConfigHorariosDeFuncionamento.current?.empresaHorarioDias.find((x) => isEmpty(x?.inicio))) {
            showToast('error', 'Horário inicial inválido!')
            return
          } else if (refConfigHorariosDeFuncionamento.current?.empresaHorarioDias.find((x) => isEmpty(x?.fim))) {
            showToast('error', 'Horário final inválido!')
            return
          }
        }

        nextStep()
      } catch (err: any) {
        showToast('error', err.message)
      }
    }
    return (
      <Grid xs={12} md={8} container justifyContent='space-between'>
        <Grid className={classes.conteudoDeEtapa} container >
          {configuracoesRealizadas?.horarios ? (
            <FormEstabelecimentoHorariosConfigInicial
              showLoading={false}
              ref={refConfigHorariosDeFuncionamentoForm}
              loading={false}
              onSubmit={handleSubmitFormHorarios}
            />
          ) : (
            <QuestionarioEtapa
              pergunta='Deseja configurar o horário de funcionamento do seu estabelecimento?'
              btnVoltar={voltarStep}
              funcaoBotao1={() => { naoUtilizaModulo('horarios') }}
              funcaoBotao2={() => { validarConfiguracoes("horarios", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />
          )}
        </Grid>
      </Grid>


    );
  }, [classes.conteudoDeEtapa, configuracoesRealizadas?.horarios, idContrato, idEmpresa, naoUtilizaModulo, nextStep, showToast, validarConfiguracoes, voltarStep]);



  const getPerfil = useCallback((): JSX.Element => {

    const perfis = [
      {
        nome: "Caixa",
        descricao: "Perfil de acesso para fazer pedidos e vendas via catálogo de produtos.",
        icone: <LeituraCaixaIcon tipo='BUTTON' />
      },
      {
        nome: "Gerente",
        descricao: "Perfil de acesso para fazer pedidos e vendas via catálogo de produtos e controle da conta.",
        icone: <UserIcon tipo='BUTTON' />
      }
    ]

    const handlePerfil = (index: number) => {
      try {
        if (perfisSelecionados && perfisSelecionados.includes(index)) {
          if (index === 0 && refConfigPerfis.current.perfis) {
            const newArr = refConfigPerfis.current.perfis.filter(item => item.nome !== 'Caixa');
            refConfigPerfis.current.perfis = newArr
          } else if (refConfigPerfis.current.perfis) {
            const newArr = refConfigPerfis.current.perfis.filter(item => item.nome !== 'Gerente');
            refConfigPerfis.current.perfis = newArr
          }
          setPerfisSelecionados(perfisSelecionados.filter((item) => item !== index))

        } else {
          setPerfisSelecionados([...perfisSelecionados, index])
          const newPerfil = new ConfigInicialPerfil()
          newPerfil.contratoId = idContrato
          if (index === 0) {
            newPerfil.nome = 'Caixa';
            newPerfil.permissoes = perfilCaixa
          } else {
            newPerfil.nome = 'Gerente';
            newPerfil.permissoes = perfilGerente

          }
          refConfigPerfis.current.perfis = [...refConfigPerfis.current.perfis || [], newPerfil]

        }
      } catch (err: any) {
        showToast('error', err.message)
      }
    }


    return (
      <Grid xs={12} md={8} container justifyContent='space-between'>
        <Grid className={classes.conteudoDeEtapa} container >
          {configuracoesRealizadas?.perfil ? (
            <Grid xs={12}>
              {perfis?.map((perfil, index) => (
                <Grid key={index} className={classes.cardPerfilContainer} xs={12} >
                  <Grid className={classes.cardPerfilContent} >
                    <Grid item xs={12} className={classes.cardPerfilTextAndIcon} >
                      <Grid xs={10} className={classes.perfilCard}>
                        {perfil?.icone}
                        <Typography variant='h6'>{perfil.nome}</Typography>
                      </Grid>
                      <Grid xs={2} className={classes.switchGrid} justifyContent='flex-end' >
                        <SwitchSaurus variant='LIGHT' onClick={() => { handlePerfil(index) }} />
                      </Grid>
                    </Grid>
                    <Grid xs={10}>
                      <Typography variant="caption">{perfil?.descricao}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <QuestionarioEtapa
              pergunta='Seu estabelecimento utiliza o serviço de permissão por perfil?'
              btnVoltar={voltarStep}
              funcaoBotao1={() => { naoUtilizaModulo('perfil') }}
              funcaoBotao2={() => { validarConfiguracoes("perfil", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />
          )}
        </Grid>
      </Grid>
    )

  }, [classes.cardPerfilContainer, classes.cardPerfilContent, classes.cardPerfilTextAndIcon, classes.conteudoDeEtapa, classes.perfilCard, classes.switchGrid, configuracoesRealizadas?.perfil, idContrato, naoUtilizaModulo, perfisSelecionados, showToast, validarConfiguracoes, voltarStep])

  const getFormNFCE = useCallback((): JSX.Element => {

    const handleSubmitConfigEmissaoNFCE = (modelo: ConfigNfceFormModel) => {
      refConfigNfce.current = modelo;
      nextStep()
    }

    return (
      <Grid xs={12} md={8} container className={classes.conteudoDeEtapa}>
        <Grid className={classes.conteudoDeEtapa} container >
          {configuracoesRealizadas?.nfce ? (
            <Box>
              <Grid className={classes.accordionNfce}>
                <AccordionSaurus
                  colorExpand={theme.palette.primary.main}
                  tipoExpand='bold'
                  showDivider
                  labelSecondary='Configurações de NFC-e'
                >
                  <FormConfigNfce
                    loading={false}
                    showLoading={false}
                    ref={refConfigNfceForm}
                    onSubmit={handleSubmitConfigEmissaoNFCE}
                  />
                </AccordionSaurus>

              </Grid>
              <Grid xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  variant='outlined'
                  onClick={() => { validarConfiguracoes('nfce', true); nextStep() }}>
                  Não possuo o certificado no momento, configurar mais tarde.
                </Button>

              </Grid>
            </Box>
          ) : (
            <QuestionarioEtapa
              pergunta='Seu estabelecimento emite NFC-e?'
              btnVoltar={voltarStep}
              funcaoBotao1={() => { naoUtilizaModulo('nfce') }}
              funcaoBotao2={() => { validarConfiguracoes("nfce", true) }}
              textoBotao1='Não'
              textoBotao2='Sim'
            />

          )}
        </Grid>
      </Grid>
    );
  }, [classes.accordionNfce, classes.conteudoDeEtapa, configuracoesRealizadas?.nfce, naoUtilizaModulo, nextStep, theme.palette.primary.main, validarConfiguracoes, voltarStep]);

  const postConfigInicialWrapper = useCallback(async (configInicialForm: ConfigInicialModel): Promise<any> => {
    try {
      const res = await postConfigInicial(configInicialForm, getEmpresaSelecionada()?.Id || '')
      if (res.erro) throw res.erro
      showToast('success', 'Configurações realizadas com sucesso!')
      await postTours(dadosTour)
      callEvent(AppEventEnum.FinalizouWizard, { finalizado: true })
      refreshPlanoUsuario()
      fecharConfiguracoesIniciais()
      return res.resultado?.data
    }
    catch (e: any) {
      showToast('error', e.message)
    }
  }, [callEvent, dadosTour, fecharConfiguracoesIniciais, getEmpresaSelecionada, postConfigInicial, postTours, refreshPlanoUsuario, showToast])
  const getEnvioDasConfiguracoes = useCallback((): JSX.Element => {

    const planosParaExibir = (planoAtual: PlanoModel, planoOfertado: PlanoModel | null) => {
      try {
        let planosOfertados: PlanoModel[] = []
        if (planoOfertado) {
          planosOfertados = [planoOfertado, planoAtual]
        } else {
          planosOfertados = [planoAtual]
        }

        return planosOfertados
      } catch (err: any) {
        showToast('error', err.message)
      }
    }
    const planosParaOferta = planosParaExibir(planoResumo, planoOfertado)

    const codigosModulos = [EnumPlanoOpcoes.Fiscal, EnumPlanoOpcoes.ControleDeAcesso, EnumPlanoOpcoes.OperacaoPedido, EnumPlanoOpcoes.VitrineDigital]

    return (
      <Grid md={8} xs={12} justifyContent='space-between'>
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        <Grid md={12} className={classes.listaDeModulos} >

          {!configuracoesRealizadas?.planoAtual ?
            (upgradesNecessarios ?? []).length > 0 ?
              isMobile ?
                <Grid container className={classes.containerPlanos} xs={12}>
                  <CarouselSaurus
                    fullScreen={false}
                    isEscuro={false}
                    isRTL={false}
                    showArrows={false}
                    noMinHeight
                  >
                    {planosParaOferta?.filter(x => x)?.map((plano, index) => {
                      return (
                        <Grid xs={12} md={6} item key={`plano-${index}-mobile`} className={classes.cardPlano}>
                          <CardPlanoConfigInicial
                            model={plano}
                            modulos={codigosModulos}
                            planoAtual={(planoResumo?.id ?? "") === plano?.id}
                            planoUpgrade={(planoResumo?.id ?? "") !== plano?.id}
                            escolherPlano={() => {
                              plano?.id === planoResumo?.id ?
                                validarConfiguracoes('planoAtual', true)
                                :
                                postConfigInicialWrapper(formPlanoUpgrade)
                            }}
                            texto={plano?.id === planoResumo?.id ? "Manter Plano Atual" : "Escolher Plano"}
                          />
                        </Grid>

                      )
                    }
                    )}
                  </CarouselSaurus>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={() => { voltarStep() }}>
                      <VoltarIcon tipo='BUTTON' fill={theme.palette.primary.main} />
                      Passo Anterior
                    </Button>
                  </Grid>

                </Grid>
                :
                <Grid container className={classes.containerPlanos} xs={12}>

                  {planosParaOferta?.filter(x => x)?.map((plano, index) => {
                    return (
                      <>
                        <Grid xs={12} md={6} item key={`plano-${index}-desktop`} className={classes.cardPlano}>
                          <CardPlanoConfigInicial
                            model={plano}
                            modulos={codigosModulos}
                            planoAtual={(planoResumo?.id ?? "") === plano?.id}
                            planoUpgrade={(planoResumo?.id ?? "") !== plano?.id}
                            escolherPlano={() => {
                              plano?.id === planoResumo?.id ?
                                validarConfiguracoes('planoAtual', true)
                                :
                                postConfigInicialWrapper(formPlanoUpgrade)
                            }}
                            texto={plano?.id === planoResumo?.id ? "Manter Plano Atual" : "Escolher Plano"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            size="large"
                            fullWidth
                            onClick={() => { voltarStep() }}>
                            <VoltarIcon tipo='BUTTON' fill={theme.palette.primary.main} />
                            Passo Anterior
                          </Button>
                        </Grid>
                      </>
                    )
                  }
                  )}
                </Grid>
              :
              <>
                <Grid className={classes.cardPlano} xs={12} >
                  <CardPlanoConfigInicial
                    model={planoResumo}
                    modulos={codigosModulos}
                    planoAtual={true}
                    planoUpgrade={false}
                    escolherPlano={() => {
                      postConfigInicialWrapper(formPlanoAtual)
                    }}
                    texto='Configurar'
                  />
                </Grid>
                <Grid xs={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => { voltarStep() }}>
                    <VoltarIcon tipo='BUTTON' fill={theme.palette.primary.main} />
                    Passo Anterior
                  </Button>
                </Grid>
              </>
            :

            <Grid container xs={12} className={classes.upgradesNecessariosContainer}>
              <Grid item className={classes.upgradesNecessariosTitle} >
                <Typography align={isMobile ? 'left' : 'center'}>
                  O que acontecerá ao escolher o plano básico?
                </Typography>
              </Grid>
              <Grid item alignItems='center' xs={12} >
                <Grid container spacing={2} justifyContent='space-between' className={classes.itemLista}>
                  <Grid xs={12} className={classes.upgradesNecessariosCard} >
                    <Grid >
                      <CloseIconCircle tipo='BUTTON' fill={theme.palette.error.main} />
                    </Grid>
                    <Grid>
                      <Typography>Você perderá todo o progresso feito até o momento.</Typography>
                    </Grid>
                  </Grid>
                  {upgradesNecessarios?.map((upgrade) =>
                    <Grid item xs={12} className={classes.upgradesNecessariosCard} >
                      <Grid>
                        <CloseIconCircle tipo='BUTTON' fill={theme.palette.error.main} />
                      </Grid>
                      <Grid >
                        <Typography>Você não terá acesso ao módulo de <strong>{upgrade}</strong></Typography>
                      </Grid>
                    </Grid>

                  )}
                </Grid>
              </Grid>
              <Grid xs={12} item alignItems='flex-end' justifyContent='space-between' className={classes.divButtonRevisao} >
                <Grid item xs={6} className={classes.buttonRevisaoLeft} >
                  <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    size="large"
                    className={classes.buttonRevisao}
                    fullWidth={true}
                    onClick={() =>
                      postConfigInicialWrapper(formPlanoAtual)
                    }>
                    <CloseIconCircle tipo='BUTTON' fill={theme.palette.primary.main} />
                    <Typography variant='button'>Manter Plano</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.buttonRevisaoRight}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    className={classes.buttonRevisao}
                    fullWidth={true}
                    onClick={async () => {
                      await postConfigInicialWrapper(formPlanoUpgrade)
                    }}>
                    <UpgradeIcon tipo="BUTTON_PRIMARY" />
                    <Typography variant='button'>Fazer Upgrade</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    );
  }, [carregando, classes.buttonRevisao, classes.buttonRevisaoLeft, classes.buttonRevisaoRight, classes.cardPlano, classes.containerPlanos, classes.divButtonRevisao, classes.itemLista, classes.listaDeModulos, classes.upgradesNecessariosCard, classes.upgradesNecessariosContainer, classes.upgradesNecessariosTitle, configuracoesRealizadas?.planoAtual, formPlanoAtual, formPlanoUpgrade, isMobile, planoOfertado, planoResumo, postConfigInicialWrapper, showToast, theme.palette.error.main, theme.palette.primary.main, upgradesNecessarios, validarConfiguracoes, voltarStep]);



  useEffect(() => {
    switch (retTelaIndex(currentStep)) {
      case EnumTelasConfigIniciais.Segmento:
        refConfigSegmentoForm.current?.fillForm(
          refConfigSegmento.current,
        );
        break;
      case EnumTelasConfigIniciais.MesasEcomandas:
        refConfigMesasEcomandasForm.current?.fillForm(
          new ConfigInicialMesasEcomandasModel(),
        );
        break;
      case EnumTelasConfigIniciais.Setores:
        refConfigSetoresForm.current?.fillForm(
          refConfigSetores.current,
        );
        break;
      case EnumTelasConfigIniciais.Cardapio:
        refConfigCardapioForm.current?.fillForm(
          new ConfigInicialCardapioModel(),
        );
        break;
      case EnumTelasConfigIniciais.HorarioDeFuncionamento:
        refConfigHorariosDeFuncionamentoForm.current?.fillForm(
          refConfigHorariosDeFuncionamento.current,
        );
        break;
      case EnumTelasConfigIniciais.NFCE:
        refConfigNfceForm.current?.fillForm(
          refConfigNfce.current,
        );
        break;
      case EnumTelasConfigIniciais.ResumoDasConfiguracoes:
        getResumo()
        break;

    }
  }, [currentStep, getResumo, modulos, ofertarPlano, retTelaIndex]);

  const iniciarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        fullWidth={true}
        disabled={false}
        onClick={avancarStep}
        className={isMobile ? classes.botaoMobile : ''}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Iniciar
      </Button>
    );
  }, [avancarStep, isMobile, classes.botaoMobile]);

  const voltarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        size="large"
        fullWidth={true}
        onClick={voltarStep}
        className={isMobile ? classes.botaoMobile : ''}
      >
        <VoltarIcon tipo="BUTTON" />
        Passo Anterior
      </Button>
    );
  }, [voltarStep, isMobile, classes.botaoMobile]);

  const avancarButton = useMemo(() => {
    return (
      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        fullWidth={true}
        onClick={avancarStep}
        className={isMobile ? classes.botaoMobile : ''}
      >
        <AvancarIcon tipo="BUTTON_PRIMARY" />
        Próximo Passo
      </Button>
    );
  }, [avancarStep, classes.botaoMobile, isMobile]);


  const ocultarDescricao = (utilizaModulo: boolean, descricao: string) => {
    if (utilizaModulo) {
      return descricao
    }
    return ""
  }

  const getFormArray = useMemo(() => {
    const ret = [];
    let i = 0;
    while (retTelaIndex(i)) {
      const tela = retTelaIndex(i);
      switch (tela) {
        case EnumTelasConfigIniciais.Saudacao:
          ret.push(
            new FormStep(
              `Bem-vindo, ${userName}!`,
              `Para efetuar a sua primeira venda, é necessário realizar o cadastro das configurações do sistema.`,
              <></>,
              null,
              <PessoaIcon tipo="GERAL" />,
              <></>,
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.Saudacao,
              null
            ),
          );
          break;
        case EnumTelasConfigIniciais.Segmento:
          ret.push(
            new FormStep(
              'Ramo',
              `Escolha abaixo o ramo do seu negócio.`,
              <RamoIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <RamoIcon tipo='GERAL' />,
              getSegmentos(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.Segmento,
              null
            ),
          );
          break;
        case EnumTelasConfigIniciais.MesasEcomandas:
          ret.push(
            new FormStep(
              "Mesas e Comandas",
              ocultarDescricao(configuracoesRealizadas.mesasEcomandas, `Realize abaixo a configuração das mesas e/ou comandas.`),
              <MesaIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <MesaIcon tipo="GERAL" />,
              getMesasEcomandas(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.MesasEcomandas,
              configuracoesRealizadas.mesasEcomandas
            ),
          );
          break;
        case EnumTelasConfigIniciais.Setores:
          ret.push(
            new FormStep(
              'Setores',
              ocultarDescricao(configuracoesRealizadas.setores, `Insira nomes dos setores que deseja incluir em seu KDS ou que deseja imprimir os seus pedidos.`),
              <SetorProcessosAddIcon tipo="BUTTON" fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <SetorProcessosAddIcon tipo="GERAL" fill={theme.palette.common.white} />,
              getSetores(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.Setores,
              configuracoesRealizadas.setores
            ),
          );
          break;
        case EnumTelasConfigIniciais.Cardapio:
          ret.push(
            new FormStep(
              'Cardápio',
              ocultarDescricao(configuracoesRealizadas.cardapio, `Escolha as configurações do cardápio.`),
              <CardapioIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <CardapioIcon tipo="GERAL" />,
              getCardapio(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.Cardapio,
              configuracoesRealizadas.cardapio
            ),
          );
          break;
        case EnumTelasConfigIniciais.HorarioDeFuncionamento:
          ret.push(
            new FormStep(
              'Horários de Funcionamento',
              ocultarDescricao(configuracoesRealizadas.horarios, `Escolha os horários de funcionamento do seu estabelecimento.`),
              <RelogioIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <RelogioIcon tipo="GERAL" />,
              getFormHorariosDeFuncionamento(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.HorarioDeFuncionamento,
              configuracoesRealizadas.horarios

            ),
          );
          break;
        case EnumTelasConfigIniciais.Perfil:
          ret.push(
            new FormStep(
              'Perfil',
              ocultarDescricao(configuracoesRealizadas.perfil, `Escolha os perfis de acesso a serem criados.`),
              <PessoasIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <NovoUsuarioIcon tipo="GERAL" />,
              getPerfil(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.Perfil,
              configuracoesRealizadas.perfil
            ),
          );
          break;
        case EnumTelasConfigIniciais.NFCE:
          ret.push(
            new FormStep(
              'Emissão de NFC-e',
              ocultarDescricao(configuracoesRealizadas.nfce, `Faça as configurações para emitir NFC-e.`),
              <NfceIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <NfceIcon tipo="GERAL" />,
              getFormNFCE(),
              voltarButton,
              avancarButton,
              EnumTelasConfigIniciais.NFCE,
              configuracoesRealizadas.nfce
            ),
          );
          break;
        case EnumTelasConfigIniciais.ResumoDasConfiguracoes:
          ret.push(
            new FormStep(
              'Revisão',
              ocultarDescricao(!configuracoesRealizadas.planoAtual, `Veja abaixo o resumo das suas configurações.`),
              <OkIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <OkIcon tipo="GERAL" />,
              getEnvioDasConfiguracoes(),
              undefined,
              undefined,
              EnumTelasConfigIniciais.ResumoDasConfiguracoes,
              null
            ),
          );
          break;
        case EnumTelasConfigIniciais.Config:
          ret.push(
            new FormStep(
              'Configurações',
              `Configurações`,
              <ConfiguracaoIcon fill={theme.palette.primary.main} style={{ height: 60, width: 60, marginRight: '1rem' }} />,
              null,
              <ConfiguracaoIcon tipo="GERAL" />,
              getEnvioDasConfiguracoes(),
              undefined,
              avancarButton,
              EnumTelasConfigIniciais.Config,
              null

            ),
          );
          break;

      }
      i++;
    }

    if (ret.length > 0) {
      ret[ret.length - 1].previousButton = undefined;
      ret[0].previousButton = undefined;
      ret[1].previousButton = undefined;
      ret[1].nextButton = undefined;
      ret[0].nextButton = iniciarButton;
    }
    return ret;
  }, [retTelaIndex, userName, voltarButton, avancarButton, theme.palette.primary.main, theme.palette.common.white, getSegmentos, configuracoesRealizadas.mesasEcomandas, configuracoesRealizadas.setores, configuracoesRealizadas.cardapio, configuracoesRealizadas.horarios, configuracoesRealizadas.perfil, configuracoesRealizadas.nfce, configuracoesRealizadas.planoAtual, getMesasEcomandas, getSetores, getCardapio, getFormHorariosDeFuncionamento, getPerfil, getFormNFCE, getEnvioDasConfiguracoes, iniciarButton]);

  return {
    formStepper: {
      currentStep,
      nextStep,
      prevStep,
      skipStep
    },
    formArray: getFormArray,
    carregando: false,
    segmentoSelecionado: segmentoSelecionado,
    formPlanoAtual: formPlanoAtual,
    dadosTour: dadosTour,
  };
};
