import { EditarIcon, GarcomIcon, InformacaoIcon } from 'views/components/icons';
import { SacoDinheiroIcon } from 'views/components/icons/saco-dinheiro-icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePDV } from 'services/app/hooks/pdv';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { roundTo } from 'utils/round-to';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { Box, Button, Divider, Grid, Typography } from 'views/design-system';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './carrinho-totais-styles';
import { PromocaoIcon } from 'views/components/icons/promocao-icon';
import { isEmpty } from 'lodash';
import { CimaIcon } from 'views/components/icons/cima-icon';
import { EnumMovModelo } from 'model';
import { DeliveryIcon } from 'views/components/icons/delivery-icon';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { CestaProdutosIcon } from 'views/components/icons/cesta-produtos-icon';

interface CarrinhoTotaisProps {
    telaDePagamento: boolean;
}

export const CarrinhoTotais = ({
    telaDePagamento = false
}: CarrinhoTotaisProps) => {
    // STATES E REFS
    const refValorOriginal = useRef<HTMLParagraphElement>(null);
    const refAPagar = useRef<HTMLParagraphElement>(null);
    const refQCom = useRef<HTMLParagraphElement>(null);
    const refInfAdicional = useRef<HTMLParagraphElement>(null);
    const refAcrescDescLabel = useRef<HTMLParagraphElement>(null);
    const refAcrescDesc = useRef<HTMLParagraphElement>(null);
    const refTaxa = useRef<HTMLParagraphElement>(null);
    const refTaxaEntrega = useRef<HTMLParagraphElement>(null);

    const { getMov, hasTaxaServicoProduto, hasTaxaEntregaProduto } = useMovAtual();
    const {
        abrirDialogInformacaoAdicional,
        abrirDialogTaxaServicoCarrinho
    } = useCadastros();
    const { xs } = useThemeQueries();
    // HOOKS
    const { addHandler, removeHandler } = useEventTools();
    const { getConfigByCod: getPdvConfigByCod } = usePDV();
    const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
    const { plano } = useSessaoAtual();
    const { showToast } = useToastSaurus();
    const { redirectVendaFrete } = useMovRota();
    const { theme } = useThemeQueries()

    // AUX
    const classes = useStyles();

    const modeloTrabalho = getPdvConfigByCod(101);
    const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);
    const exibirServico = !isFarmaceutico && hasTaxaServicoProduto();
    const exibirTaxaEntrega = hasTaxaEntregaProduto();
    const [exibirOutrosValores, setExibirOutrosValores] = useState(!xs);

    const preencherTela = useCallback(() => {
        const isSomentePedido = [EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS].includes(modeloTrabalho as EnumModeloDeTrabalho);

        const mov = getMov();

        const valorAPagar = (mov?.vNF ?? 0) - (mov?.vPago ?? 0);
        const vDescAcresc = (mov?.vNF ?? 0) - (mov?.vProdMax ?? 0);

        let valorTaxaServico = 0;
        mov?.produtos
            .filter((x) => x.ativo && x.indFin)
            .forEach((x) => {
                if (x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico))
                    valorTaxaServico += x.vFinal;
            });


        let quantidade = mov?.produtos
            .filter((x) => x.ativo && x.indFin && x.produtoId !== getContratoConfigByCod(EnumContratoConfig.ProdutoServico) && x.produtoId !== getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega))
            .reduce((prev, curr) => prev + (curr.qComModificador > 0 ? curr.qComModificador : curr.qCom), 0) ?? 0

        const prodTaxaEntrega = mov?.produtos.find(x => x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega));
        const vProdTaxaServico = (mov?.produtos ?? [])
            .filter(x => x.produtoId === getContratoConfigByCod(EnumContratoConfig.ProdutoServico) && x.ativo)
            .reduce((prev, curr) => prev + curr.vFinal, 0)

        let vNF = mov?.vProdMax ?? 0;
        if (isSomentePedido || mov?.mod === EnumMovModelo.DELIVERY) {
            vNF = vNF - (prodTaxaEntrega?.vFinal ?? 0);
        }


        vNF = vNF - (vProdTaxaServico ?? 0)

        //PREENCHE O VALOR A PAGAR
        if (refAPagar.current)
            refAPagar.current.textContent = `R$ ${toDecimalString(valorAPagar, 2)} `;

        if (refAcrescDescLabel.current)
            refAcrescDescLabel.current.textContent = vDescAcresc > 0 ? 'Acréscimo' : 'Desconto'

        if (refAcrescDesc.current)
            refAcrescDesc.current.textContent = `R$ ${toDecimalString(Math.abs(vDescAcresc), 2)}`

        if (refTaxa.current)
            refTaxa.current.textContent = `R$ ${toDecimalString(roundTo(valorTaxaServico))}`;

        if (refTaxaEntrega.current)
            refTaxaEntrega.current.textContent = `R$ ${toDecimalString(roundTo(prodTaxaEntrega?.vFinal ?? 0))}`;

        if (refQCom.current)
            refQCom.current.textContent = formatDecimalInteger(quantidade, 3) + (quantidade === 1 ? ' Item' : ' Itens');

        if (refValorOriginal.current)
            refValorOriginal.current.textContent = `R$ ${toDecimalString(roundTo(vNF))}`;

        if (refInfAdicional.current && exibirOutrosValores)
            refInfAdicional.current.textContent = isEmpty(mov?.infAdicional ?? '') ? '-' : (mov?.infAdicional ?? '');

    }, [exibirOutrosValores, getContratoConfigByCod, getMov, modeloTrabalho]);


    useEffect(() => {
        addHandler(AppEventEnum.MovAtualProdAlterado, preencherTela);
        addHandler(AppEventEnum.MovAtualAlterada, preencherTela);
        addHandler(AppEventEnum.PagamentoEfetuado, preencherTela);
        addHandler(AppEventEnum.AlterarDisplayKeybordPayment, preencherTela)

        return () => {
            removeHandler(AppEventEnum.MovAtualProdAlterado, preencherTela);
            removeHandler(AppEventEnum.MovAtualAlterada, preencherTela);
            removeHandler(AppEventEnum.PagamentoEfetuado, preencherTela);
            removeHandler(AppEventEnum.AlterarDisplayKeybordPayment, preencherTela)
        };
    }, [addHandler, preencherTela, removeHandler]);

    useEffect(() => {
        preencherTela();
    }, [preencherTela])

    return (
        <>
            <Grid container justifyContent='space-between' p={1}>
                {!telaDePagamento && exibirOutrosValores && (
                    <>
                        <Grid flex item xs={12} alignItems='center' justifyContent='space-between' py={1} px={0} >
                            <Grid xs={10} container >
                                <Grid item xs={12} flex alignItems='center'>
                                    <InformacaoIcon class={classes.iconCustom}
                                        tipo="BUTTON"
                                    />
                                    <Typography variant='body2'>Informações</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.conteudoInfo} ref={refInfAdicional}  >...</Typography>
                                </Grid>
                            </Grid>

                            <Grid xs={2} flex justifyContent='flex-end' >
                                <Button className={classes.botaoRedondo} tabIndex={-1} variant='contained' size='small' rounded onClick={() => {
                                    abrirDialogInformacaoAdicional('', false);
                                }}>
                                    <EditarIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid xs={12} mb={2} >
                            <Divider variant='fullWidth' className={classes.divider} />
                        </Grid>
                    </>
                )}
                <Grid item flex xs={7} justifyContent='space-between'>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        {exibirServico && exibirOutrosValores && !telaDePagamento && (
                            <Grid flex item xs='auto' alignItems='center' justifyContent='center'>
                                <Grid container>
                                    <Grid item xs={9} >
                                        <Box flex alignItems='center'>
                                            <GarcomIcon
                                                tipo="BUTTON"
                                                class={classes.iconCustom2}
                                            />
                                            <Typography variant='caption'>Serviço</Typography>
                                        </Box>
                                        <Box >
                                            <Typography ref={refTaxa}>Calculando...</Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button tabIndex={-1} className={classes.botaoRedondo} variant='contained' size='small' rounded onClick={
                                            () => {
                                                if (
                                                    (
                                                        getMov()?.produtos.filter(
                                                            (x) =>
                                                                x.produtoId ===
                                                                getContratoConfigByCod(EnumContratoConfig.ProdutoServico)
                                                        ) ?? []
                                                    ).length <= 0
                                                ) {
                                                    showToast('error', 'Não existe produtos para parametrizar a Taxa de Serviços.');
                                                    return;
                                                }
                                                abrirDialogTaxaServicoCarrinho();
                                            }
                                        }>
                                            <EditarIcon tipo='BUTTON' />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid flex flexDirection='column' item md='auto' alignItems='center' justifyContent='center'>
                            <Box flex alignItems='center'>
                                <CestaProdutosIcon
                                    class={classes.iconCustom2}
                                    tipo="BUTTON"
                                />
                                <Typography variant='caption' ref={refQCom}>Total</Typography>
                            </Box>
                            <Box>
                                <Typography ref={refValorOriginal} >
                                    Calculando...
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid flex flexDirection='column' item md='auto' alignItems='flex-end' justifyContent='center' >
                            <Box flex alignItems='center'>
                                <PromocaoIcon tipo="BUTTON" class={classes.iconCustom2} />
                                <Typography variant='caption' ref={refAcrescDescLabel} ></Typography>
                            </Box>
                            <Box>
                                <Typography ref={refAcrescDesc} className={classes.labelValor}>Calculando...</Typography>
                            </Box>
                        </Grid>

                        {exibirTaxaEntrega && exibirOutrosValores && (
                            <Grid flex item md='auto' alignItems='flex-end' justifyContent='center'>
                                <Box >
                                    <Box flex alignItems='center'>
                                        <DeliveryIcon
                                            tipo="BUTTON"
                                        />
                                        <Typography variant='caption' >Entrega</Typography>
                                    </Box>
                                    <Box >
                                        <Typography ref={refTaxaEntrega} >Calculando...</Typography>
                                    </Box>
                                </Box>

                                {!telaDePagamento && (
                                    <Box>
                                        <Button className={classes.botaoRedondo} tabIndex={-1} variant='contained' size='small' rounded onClick={
                                            async () => {
                                                if (
                                                    (
                                                        getMov()?.produtos.filter(
                                                            (x) =>
                                                                x.produtoId ===
                                                                getContratoConfigByCod(EnumContratoConfig.ProdutoEntrega)
                                                        ) ?? []
                                                    ).length <= 0
                                                ) {
                                                    showToast('error', 'Não existe produtos para parametrizar a Taxa de Entrega.');
                                                    return;
                                                }
                                                await redirectVendaFrete();
                                            }
                                        }>
                                            <EditarIcon tipo='BUTTON' />
                                        </Button>
                                    </Box>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {!telaDePagamento && (
                    <Grid xs={1} item className={classes.botaoExibir}>
                        <Button
                            className={classes.botaoRedondo}
                            tabIndex={-1}
                            variant='contained'
                            size='small'
                            rounded
                            onClick={() => { setExibirOutrosValores(!exibirOutrosValores) }}>
                            {exibirOutrosValores ?
                                <CimaIcon tipo='BUTTON' fill={theme.palette.primary.main} class={classes.exibirIconClose} />
                                :
                                <CimaIcon tipo='BUTTON' class={classes.exibirIcon} />
                            }
                        </Button>
                    </Grid>
                )}

                <Grid className={classes.totaisCard} alignItems='flex-end' justifyContent='center' item xs='auto' px={1} ml={1}>
                    <Box flex alignItems='center'>
                        <SacoDinheiroIcon
                            tipo="BUTTON"
                            class={classes.iconCustom2}
                        />
                        <Typography variant='body1' >À Pagar</Typography>
                    </Box>
                    <Box >
                        <Typography
                            ref={refAPagar}
                            className={classes.destaqueTotais}
                        >Calculando...</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Divider variant='fullWidth' className={classes.divider} />
                </Grid>
            </Grid>
        </>
    );
};
