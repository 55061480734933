import { useStyles } from './pesquisa-produto-styles';
import { Grid } from '@material-ui/core';
import { useState, useCallback, useRef, useEffect } from 'react';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { PesquisarProdutoListData } from '../produto-list/produto-list-data';
import { useThemeQueries } from 'views';
import { useMovProd } from 'services/app/hooks/mov-prod';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { TabResultadoPesquisa } from '../tab-resultado-pesquisa/tab-resultado-pesquisa';
import { PesquisarPrincipioAtivoListData } from '../principio-ativo-list/principio-ativo-list-data';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { MedicamentoPreco } from 'model/api/gestao/medicamento/medicamento-model';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { CardNaoEncontrado } from 'views/components/cards';
import { TecladoIcon } from 'views/components/icons/teclado-icon';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';
import { useKeyNavigation } from 'services/app/hooks/key-navigation';
import { useKeyControls } from 'services/app/hooks/key-controls';
import { EnumKey } from 'model/enums/enum-keycode';
import { isOnlyHasNumber } from 'utils/to-decimal';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { useMovRota } from 'services/app/hooks/mov-rota';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';

export const PesquisaProduto = () => {
  // REFS
  const timer = useRef({} as NodeJS.Timeout);
  const abertoPorClick = useRef<boolean>(false);
  const firstRender = useRef<boolean>(true);
  const selectedIndex = useRef<number>(-1);

  // STATES
  const [aberto, setAberto] = useState<boolean>(false);
  const [aguardandoBusca, setAguardandoBusca] = useState(false);
  const [listProdutos, setListProdutos] = useState<ProdutoResumidoModel[]>([]);
  const [listProdutosVarejo, setListProdutosVarejo] = useState<ProdutoResumidoModel[]>([]);
  const [listMedicamentosSinc, setListMedicamentosSinc] = useState<ProdutoResumidoModel[]>([]);
  const [listPrincipioAtivo, setPrincipioAtivo] = useState<string[]>([]);
  const [path, setPath] = useState<string>('/produtos');
  const dialogProdutoAberto = useRef<boolean>(false);

  // HOOKS
  const { plano } = useSessaoAtual();
  const { inserirProdutoDefault, inserirProdutoFromSearchDetailed, inserirProdutoByCodigo } = useMovProd();
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const termoPesquisar = useVendaHeaderStore(state => state.props.search) || ''
  const setTermoPesquisar = useVendaHeaderStore(state => state.setSearch);
  const { getPedidoSalao } = useGetPedidoSalao();
  const { searchComandas } = usePedidoLocal();
  const { redirectVisualizarPedidoComanda } = useMovRota();
  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();
  const { showToast } = useToastSaurus();
  const { getConfigByCod, getEmpresaAtual } = useEmpresaAtual();
  const isFarma = isPlanoFarmaceutico(plano?.plano);

  useKeyNavigation(
    listProdutos.length ||
    listProdutosVarejo.length ||
    listMedicamentosSinc.length ||
    listPrincipioAtivo.length,
    selectedIndex,
    undefined,
    isFarma ? {
      forwardKeys: [EnumKey.ARROW_DOWN],
      backwardKeys: [EnumKey.ARROW_UP]
    } : undefined
  )
  useKeyControls(
    {
      onForward: isFarma ? () => {
        if (dialogProdutoAberto.current) return;
        switch (path) {
          case '/produtos':
            setPath('/medicamentos')
            break;
          case '/medicamentos':
            setPath('/varejo')
            break;
          case '/varejo':
            setPath('/principioAtivo');
            break;
          case '/principioAtivo':
            setPath('/produtos');
            break;
        }
      } : undefined,
      onBackward: isFarma ? () => {
        if (dialogProdutoAberto.current) return;
        switch (path) {
          case '/produtos':
            setPath('/principioAtivo')
            break;
          case '/medicamentos':
            setPath('/produtos')
            break;
          case '/varejo':
            setPath('/medicamentos');
            break;
          case '/principioAtivo':
            setPath('/varejo');
            break;
        }
      } : undefined,
      overrideKeys: isFarma ? {
        forwardKeys: [EnumKey.ARROW_RIGHT],
        backwardKeys: [EnumKey.ARROW_LEFT],
      } : undefined
    }
  )

  const searchProducts = useCallback(
    async (termo: string) => {
      const res = await TouchoneDBPrimary.produtos
        .filter(
          (prod) =>
            prod.tipo === EnumTipoProduto.Produto ||
            prod.tipo === EnumTipoProduto.Combo ||
            prod.tipo === EnumTipoProduto.Medicamento
        )
        .filter((produto) => {
          if (termo.length === 0) {
            return false;
          }
          if (produto.medicamento?.nome?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.medicamento?.composicao?.toLowerCase().includes(termo.toLowerCase())) {
            return true
          }
          if (
            produto.nome?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.codigo?.toLowerCase().includes(termo.toLowerCase()) ||
            produto.codigoAnvisa?.toLowerCase().includes(termo.toLowerCase())
          ) {
            return true;
          }
          return false;
        }).limit(100)
        .toArray();

      setListProdutos(res as unknown as ProdutoResumidoModel[]);
      const varejo = res.filter(p => !p.codigoAnvisa);
      const medicamentos = res.filter(p => p.medicamento);
      setListProdutosVarejo(varejo as unknown as ProdutoResumidoModel[]);
      setListProdutos(res as unknown as ProdutoResumidoModel[]);
      setListMedicamentosSinc((medicamentos as ProdutoResumidoModel[]) ?? []);

      const principioAtivoSet = new Set();
      (medicamentos.flatMap(x => x.medicamento) as MedicamentoPreco[]).forEach((produto: MedicamentoPreco) => {
        if (produto.composicao) {
          principioAtivoSet.add(produto.composicao);
        }
      });
      const principioAtivoArray = Array.from(principioAtivoSet);

      setPrincipioAtivo(principioAtivoArray as string[]);

      callEvent(AppEventEnum.QtdPrincipioAtivo, principioAtivoArray.length ?? 0);
      callEvent(AppEventEnum.QtdProdutos, res.length);
      callEvent(AppEventEnum.QtdVarejo, varejo.length);
      callEvent(AppEventEnum.QtdMedicamentos, medicamentos.length ?? 0);

      return res as unknown as ProdutoResumidoModel[] || [];
    },
    [callEvent]
  );

  const search = useCallback(async () => {
    try {
      if (termoPesquisar.length > 0) {
        const termo = termoPesquisar.includes('*') ? (termoPesquisar.split('*')[1] || termoPesquisar) : termoPesquisar;
        await searchProducts(termo);
      } else {
        if (isFarma) {
          setPrincipioAtivo([]);
          setListMedicamentosSinc([]);
          callEvent(AppEventEnum.QtdPrincipioAtivo, 0);
          callEvent(AppEventEnum.QtdMedicamentos, 0);
          callEvent(AppEventEnum.QtdVarejo, 0);
          callEvent(AppEventEnum.QtdRevista, 0);
        }
        setListProdutos([]);
        callEvent(AppEventEnum.QtdProdutos, 0);
      }
    } catch (e: any) {
      showToast('error', e.message);
    } finally {
      setAguardandoBusca(false);
    }
  }, [callEvent, isFarma, searchProducts, showToast, termoPesquisar]);

  const getPedidoSalaoWrapper = useCallback(async (comandaId: string) => {

    const query = `comandaId=${comandaId}`

    const res = await getPedidoSalao(getEmpresaAtual()?.id!, query);

    if (res.erro) throw res.erro

    return res.resultado?.data.list as PedidoModel[]
  }, [getEmpresaAtual, getPedidoSalao])

  useEffect(() => {
    // if (isOpened && !isMobile) {
    //   callEvent(AppEventEnum.PermiteEntradaDigitada, false);
    // } else {
    //   callEvent(AppEventEnum.PermiteEntradaDigitada, true);
    // }

    const abrirPorClick = (abrir: boolean) => {
      if (abrir) {
        abertoPorClick.current = true;
        setAberto(true);
        return
      }
      setTermoPesquisar('');
      abertoPorClick.current = false;
      setAberto(false);
    }


    const inserir = async ({
      termo,
    }: { termo: string, isCodigo?: boolean }) => {
      try {
        let isCodigo = false;

        if (termo.includes('*') && !isNaN(Number(termo.split('*')[1]))) {
          isCodigo = true;
        }

        const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
        const prefixo = buscaPrefixo?.toUpperCase();

        if (!isEmpty(prefixo) && termo.toUpperCase().startsWith(prefixo!)) {

          let codComanda: any = termo;

          if (codComanda?.toUpperCase().startsWith(prefixo)) {
            codComanda = termo.slice(prefixo?.length);
          }

          if (isOnlyHasNumber(codComanda)) {
            codComanda = Number(codComanda);
          }

          const comanda = await searchComandas(codComanda);
          if (isEmpty(comanda)) {
            showToast('error', 'Comanda não localizada')
            return;
          } else {
            const resposta = await getPedidoSalaoWrapper(comanda[0].id);
            const pedidos = resposta.filter(pedido => pedido.statusPedido.codigo !== EnumStatusPedido.FECHADO)

            if (isEmpty(pedidos)) {
              showToast('error', 'Nenhum Pedido encontrado na comanda ' + prefixo + codComanda + '.')
              return;
            }
            redirectVisualizarPedidoComanda(comanda[0].id);
            return
          }

        }

        if (!isNaN(Number(termo))) {
          isCodigo = true;
        }

        if (termo && isCodigo) {
          const prod = await inserirProdutoByCodigo(termo);
          if (prod) {
            if (prod.subItens?.length === 0) {
              showToast('success', `${prod.nome} adicionado ao carrinho.`)
            }
            setTermoPesquisar('')
            setAberto(false);
          }
          return
        }

        let search = termo.includes('*') ? termo.split('*')[1] : termo;

        const prods = await searchProducts(search);

        const qtd = termo.includes('*') && !isNaN(Number(termo.split('*')[0])) ? Number(termo.split('*')[0]) : 1;

        if (prods.length === 1) {
          await inserirProdutoDefault(prods[0], qtd);
          // showToast('success', `${prods[0].nome} adicionado ao carrinho.`)
          setTermoPesquisar('')
          setAberto(false);
          return
        }

        if (selectedIndex.current > -1) {
          await inserirProdutoFromSearchDetailed(prods[selectedIndex.current]);
          return
        }
      } catch (e: any) {
        showToast('error', e.message)
        setTermoPesquisar('');
      }
    }
    setTermoPesquisar('');

    addHandler(AppEventEnum.TelaPesquisa, abrirPorClick)
    addHandler(AppEventEnum.SubmitPesquisa, inserir)

    return () => {
      removeHandler(AppEventEnum.TelaPesquisa, abrirPorClick)
      removeHandler(AppEventEnum.SubmitPesquisa, inserir)
    }
  }, [addHandler, callEvent, getConfigByCod, getPedidoSalaoWrapper, inserirProdutoByCodigo, inserirProdutoDefault, inserirProdutoFromSearchDetailed, isMobile, redirectVisualizarPedidoComanda, removeHandler, searchComandas, searchProducts, setTermoPesquisar, showToast]);

  useEffect(() => {

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setAguardandoBusca(true);
    timer.current = setTimeout(async () => {
      let isCodigo = false;

      if (termoPesquisar.includes('*') && !isNaN(Number(termoPesquisar.split('*')[1]))) {
        isCodigo = true;
      }

      if (!isNaN(Number(termoPesquisar))) {
        isCodigo = true;
      }

      const buscaPrefixo = getConfigByCod(EnumEmpresaConfig.PrefixoDeComanda);
      const prefixo = buscaPrefixo?.toUpperCase();
      if (prefixo &&
        (
          prefixo.toUpperCase().startsWith(termoPesquisar.slice(0, prefixo.length).toUpperCase()) &&
          !isNaN(Number(termoPesquisar.slice(prefixo.length)))
        )
      ) {
        setAberto(false);
        return
      }

      if (termoPesquisar.length > 0 && !isCodigo) {
        setAberto(true);
      } else if (!abertoPorClick.current) {
        setAberto(false);
      }
      await search();
    }, 600);

    return () => {
      clearTimeout(timer.current);
    };
  }, [getConfigByCod, search, termoPesquisar]);

  const onCardClickedProdutos = useCallback(
    async (produto: ProdutoResumidoModel) => {
      try {
        await inserirProdutoDefault(produto);
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [inserirProdutoDefault, showToast]
  );

  const onCardHoldedProdutos = useCallback(
    async (produto: ProdutoResumidoModel) => {
      try {
        await inserirProdutoFromSearchDetailed(produto);
      } catch (e: any) {
        showToast('error', e.message)
      }
    },
    [inserirProdutoFromSearchDetailed, showToast]
  );

  const onCardClickedPrincipioAtivo = useCallback(async (principio: string) => {
    setTermoPesquisar(principio);
    setPath('/medicamentos');
  }, [setTermoPesquisar]);

  useEffect(() => {
    const alterarRef = ({ aberto }: { aberto: boolean }) => {
      dialogProdutoAberto.current = aberto;
    }
    addHandler(AppEventEnum.AdicionarProdutoDialog, alterarRef)
    return () => {
      removeHandler(AppEventEnum.AdicionarProdutoDialog, alterarRef);
    }
  }, [addHandler, removeHandler])

  return (
    <>
      {aberto && (
        <Grid className={classNames(classes.root)}>
          <Grid className={classes.tab}>
            <TabResultadoPesquisa setPath={setPath} path={path} planoFarmaceutico={isFarma} />
          </Grid>
          <Grid item xs={12} className={classes.list}>
            {isEmpty(termoPesquisar) && (
              <CardNaoEncontrado
                mensagem={`Digite alguma informação para localizarmos os produtos.`}
                icon={<TecladoIcon tipo="GERAL" />}
              />
            )}
            {!isEmpty(termoPesquisar) && (
              <>
                {path === '/produtos' && (
                  <PesquisarProdutoListData
                    carregando={aguardandoBusca}
                    list={listProdutos}
                    onCardClicked={onCardClickedProdutos}
                    onCardHolded={onCardHoldedProdutos}
                  />
                )}

                {isFarma && path === '/medicamentos' && (
                  <PesquisarProdutoListData
                    carregando={aguardandoBusca}
                    list={listMedicamentosSinc}
                    onCardClicked={onCardClickedProdutos}
                    onCardHolded={onCardHoldedProdutos}
                  />
                )}

                {isFarma && path === '/varejo' && (
                  <PesquisarProdutoListData
                    carregando={aguardandoBusca}
                    list={listProdutosVarejo}
                    onCardClicked={onCardClickedProdutos}
                    onCardHolded={onCardHoldedProdutos}
                  />
                )}

                {isFarma && path === '/principioAtivo' && (
                  <PesquisarPrincipioAtivoListData
                    carregando={aguardandoBusca}
                    list={listPrincipioAtivo}
                    onCardClicked={onCardClickedPrincipioAtivo}
                  />
                )}

              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};