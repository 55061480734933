import { useCallback } from 'react';
import { EnumMenu, PerfilModel, PerfilPermissaoModel } from 'model';
import { ModulosRetaguardaMock } from 'data/mocks';
import { MenuModel } from '../../../model/app';
import { isEmpty } from 'lodash';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { useSessaoAtual } from 'services/app';
import { isPlanoComissoes, isPlanoConvenio, isPlanoEntradaMercadoria, isPlanoFarmaceutico, isPlanoPromocoes } from 'utils/plano-utils';

export const useGetMenu = () => {
  const { getPerfil, getEmpresaSelecionada, usuario, plano } = useSessaoAtual();

  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano)
  const isConvenio = isPlanoConvenio(plano?.plano)
  const isPromocao = isPlanoPromocoes(plano?.plano)
  const isComissao = isPlanoComissoes(plano?.plano)
  const isEntrada = isPlanoEntradaMercadoria(plano?.plano)

  const validaModuloNosGrupos = useCallback(
    (array: Array<PerfilPermissaoModel>, modulo: MenuModel): boolean => {
      if (!array) return false;
      const permissoes = array.filter(
        (x) =>
          modulo.permissoes.includes(parseInt(x.codigo.toString())) &&
          x.valor === 'true'
      );
      return permissoes.length > 0;
    },
    []
  );

  const validaModuloNasPermissoes = useCallback(
    (perfil: PerfilModel, modulo: MenuModel): boolean => {
      if (modulo?.permissoes?.length === 0) return true;
      if (!perfil?.permissoes) return true;

      let perms = new Array<PerfilPermissaoModel>();

      //CRIO UM OBJ APENAS COM OS ARRAYS
      perfil.permissoes.forEach((permissao) => {
        perms = perms.concat(permissao);
      });

      return validaModuloNosGrupos(perms, modulo);
    },
    [validaModuloNosGrupos]
  );

  const validaSeFilhosTemPermissao = useCallback(
    (array: Array<MenuModel>, modulo: MenuModel): boolean => {
      const filhos = array.filter((x) => x.codigoPai === modulo.codigo);
      const filhos_original = ModulosRetaguardaMock.filter(
        (x) => modulo.codigo === x.codigoPai
      );
      return filhos.length > 0 || filhos_original.length === 0;
    },
    []
  );

  const getMenu = useCallback(() => {
    const emp = getEmpresaSelecionada();
    let perfilId = emp?.Perfil.Id;
    if (isEmpty(emp)) {
      perfilId = usuario?.empresa[0].Perfil.Id;
    }

    const perfilAtual: PerfilModel = getPerfil().filter((x) => x.id === perfilId)[0];
    let modulos_filtered = ModulosRetaguardaMock
      //FILTRO MODULOS SEM PERMISSAO
      .filter((x) => {
        return validaModuloNasPermissoes(perfilAtual, x);
      })
      .filter(modulo => {
        let ret = true;

        if (isFarmaceutico && ret)
          ret = !(modulo.codigo === EnumMenu.ADMINISTRATIVO_SETOR_PROCESSOS || modulo.codigo === EnumMenu.ADMINISTRATIVO_SALOES)
        if (!isFarmaceutico && ret)
          ret = !(modulo.codigo === EnumMenu.FARMA_SNGPC)
        if (!isConvenio && ret)
          ret = !(modulo.codigo === EnumMenu.CADASTRO_CONVENIO || modulo.codigo === EnumMenu.RELATORIOS_CONVENIO_FATURAS)
        if (!isPromocao && ret)
          ret = !(modulo.codigo === EnumMenu.CADASTRO_PROMOCAO)
        if (!isComissao && ret)
          ret = !(modulo.codigo === EnumMenu.CADASTRO_COMISSAO)
        if (!isEntrada && ret)
          ret = !(modulo.codigo === EnumMenu.RELATORIOS_ENTRADA_MERCADORIA || modulo.codigo === EnumMenu.RELATORIOS_MANIFESTOS)

        return ret;
      }
      )
      .sort((firstEl, secondEl) => firstEl.posicao - secondEl.posicao);

    //TIRO OS PAIS SEM FILHOS
    modulos_filtered = modulos_filtered.filter((x) =>
      validaSeFilhosTemPermissao(modulos_filtered, x)
    ).map(modulo => {
      if (isFarmaceutico && modulo.codigo === EnumMenu.ADMINISTRATIVO_MEU_CARDAPIO) {
        return {
          ...modulo,
          nome: 'Minha Vitrine'
        }
      }
      if (isFarmaceutico && modulo.codigo === EnumMenu.ADMINISTRATIVO_MESAS_COMANDAS) {
        return {
          ...modulo,
          nome: 'Comandas'
        }
      }

      if (isFarmaceutico && modulo.codigo === EnumMenu.CADASTRO_PRODUTOS) {
        return {
          ...modulo,
          nome: 'Medicamentos e Produtos'
        }
      }

      return modulo
    })


    // modulos que não ficaram disponiveis na maquininha
    const modulosQueNaoVaoParaMaquininha = [
      EnumMenu.FISCAL_ATIVAR_REVISAR_DADOS_NFCE,
      EnumMenu.FISCAL_RETIRADA_DE_DOCUMENTOS_FISCAIS,
      EnumMenu.ADMINISTRATIVO_ACESSOS_PERMISSOES,
      EnumMenu.FISCAL_GROUP
    ];

    // filtro os modulos que não ficaram disponiveis na maquininha
    if (VariaveisAmbiente.paymentDevice === EnumDeviceType.CORDOVA_POS) {
      modulos_filtered = modulos_filtered.filter(
        (x) => !modulosQueNaoVaoParaMaquininha.includes(x.codigo)
      );
    }

    return modulos_filtered;
  },
    [getEmpresaSelecionada, getPerfil, usuario?.empresa, validaModuloNasPermissoes, isFarmaceutico, isConvenio, isPromocao, isComissao, isEntrada, validaSeFilhosTemPermissao]
  );

  return { getMenu };
};
