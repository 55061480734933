import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { makeUtilClasses, useThemeQueries } from 'views/theme';
import { useRef } from 'react';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { PessoaCadastroFormModel } from 'model/app/forms/pessoa/pessoa-cadastro-form-model';
import {
  DefaultFormProps,
  DefaultFormRefs
} from 'views/components/form/utils/form-default-props';
import { useFormPessoaValidation } from './form-pessoa-cadastro-validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PessoaDocumentoModel } from 'model/api/gestao/pessoa/pessoa-documento-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { UFMock } from 'data/mocks';
import { TipoDocumentoOrgaoExpedidorMock } from 'data/mocks/orgao-expedidor-mock';
import { EnumCadastroTipo } from 'model';
import { TipoDocumentoOrgaoExpedidorEnum } from 'model/enums/enum-orgao-expedidor';
import { EnumTipoDocumento } from 'model/enums/enum-tp-documento';
import {
  AutocompletePessoas,
  EnumNomeCnpj
} from 'views/components/controles/autocompletes/autocomplete-pessoa/autocomplete-pessoa';
import { PessoaModel } from 'model/api/gestao/pessoa';
import { RepresentanteCliente } from 'model/api/gestao/pessoa/pessoa-model';
import { EnumTour } from 'model/enums/enum-tour';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';

interface FormPessoaCadastroProps
  extends DefaultFormProps<PessoaCadastroFormModel> {
  showCpf: boolean;
  showDocs: boolean;
  cpfObrigatorio: boolean;
  tipo: EnumCadastroTipo;
}

export const FormPessoaCadastro = forwardRef<
  DefaultFormRefs<PessoaCadastroFormModel>,
  FormPessoaCadastroProps
>(
  (
    {
      showCpf,
      showDocs,
      cpfObrigatorio,
      tipo,
      ...props
    }: FormPessoaCadastroProps,
    ref
  ) => {
    const utilClasses = makeUtilClasses();
    const refInputCnpjCpf = useRef<HTMLInputElement>(null);
    const { FormPessoaValidationYup } = useFormPessoaValidation({
      cpfObrigatorio,
      showDocs
    });
    const { isMobile } = useThemeQueries();
    const { getEmpresaAtual } = useEmpresaAtual();

    const {
      handleSubmit,
      control,
      formState: { errors, touchedFields },
      setValue,
      getValues,
      reset
    } = useForm<PessoaCadastroFormModel>({
      resolver: yupResolver(FormPessoaValidationYup),
      criteriaMode: 'all',
      mode: 'onChange',
      defaultValues: {
        ...new PessoaCadastroFormModel(),
        documento: {
          ...new PessoaDocumentoModel(),
          orgaoExpedicao:
            tipo === EnumCadastroTipo.PRESCRITOR
              ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalMedicina
              : tipo === EnumCadastroTipo.RESPONSAVEL_TECNICO
                ? TipoDocumentoOrgaoExpedidorEnum.ConselhoRegionalFarmacia
                : TipoDocumentoOrgaoExpedidorEnum.SecretariaEstadoSeguranca,
          ufExpedicao: getEmpresaAtual()?.uf || ''
        }
      }
    });

    const onSubmit = (values: PessoaCadastroFormModel) => {
      if (showDocs) {
        values.tipoDocumento = EnumTipoDocumento.CarteiraRegistroProfissional;
      }
      props.onSubmit(values);
    };

    useImperativeHandle(ref, () => ({
      submitForm: async () => {
        await handleSubmit(onSubmit)();
      },
      resetForm: () => {
        setValue('nome', '');
        setValue('cpfcnpj', '');
        setValue('tpCadastro', tipo);
        reset({
          cpfcnpj: '',
          nome: '',
          tpCadastro: undefined,
          documento: new PessoaDocumentoModel()
        });

        if (!isMobile) refInputCnpjCpf.current?.focus();
      },
      fillForm: () => {
        if (!isMobile) refInputCnpjCpf.current?.focus();
      },
    }));

    const { callTour } = useTourSaurus()


    useEffect(() => {
      callTour(EnumTour.FORMPESSOAS)
    }, [callTour])

    return (
      <>
        <div className={utilClasses.formContainer}>
          {props.loading && props.showLoading ? (
            <div className={utilClasses.controlLoading}>
              <CircularLoading tipo="NORMAL" />
            </div>
          ) : null}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={props.loading ? utilClasses.controlLoading : ''}
          >
            <Box id="tour-form-pessoas" my={2}>
              <Grid container spacing={2}>
                {showCpf && (
                  <Grid item xs={12}>
                    <Controller
                      name="cpfcnpj"
                      control={control}
                      render={({ field }) => (
                        <TextFieldSaurus
                          tipo="CNPJ_CPF"
                          disabled={props.loading}
                          fullWidth
                          variant="outlined"
                          label="CPF/CNPJ"
                          allowSubmit
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={
                            !cpfObrigatorio
                              ? 'Informe o CPF ou CNPJ (Opcional)'
                              : 'Informe o CPF ou CNPJ'
                          }
                          error={Boolean(
                            errors.cpfcnpj && errors.cpfcnpj.message
                          )}
                          helperText={
                            touchedFields.cpfcnpj || errors.cpfcnpj
                              ? errors.cpfcnpj?.message
                              : undefined
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        tipo={'TEXTO'}
                        disabled={props.loading}
                        fullWidth
                        variant="outlined"
                        label="Nome Completo / Razão Social"
                        allowSubmit
                        InputLabelProps={{
                          shrink: true
                        }}
                        placeholder="Ex: João da Silva"
                        error={Boolean(errors.nome && errors.nome.message)}
                        helperText={
                          touchedFields.nome || errors.nome
                            ? errors.nome?.message
                            : undefined
                        }
                        {...field}
                      />
                    )}
                  />
                </Grid>

                {showDocs && (
                  <>
                    <Grid item sm={5} xs={12}>
                      <Controller
                        name="documento.documento"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="TEXTO"
                            disabled={props.loading}
                            fullWidth
                            variant="outlined"
                            label="Documento profissional"
                            allowSubmit
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder={
                              tipo === EnumCadastroTipo.PRESCRITOR ||
                                tipo === EnumCadastroTipo.RESPONSAVEL_TECNICO
                                ? 'Número'
                                : 'Número (Opcional)'
                            }
                            error={Boolean(
                              errors.documento?.documento &&
                              errors.documento?.documento.message
                            )}
                            helperText={
                              touchedFields.documento?.documento ||
                                errors.documento?.documento
                                ? errors.documento?.documento?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={3} xs={4}>
                      <Controller
                        name="documento.ufExpedicao"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            disabled={props.loading}
                            conteudo={UFMock}
                            fullWidth
                            variant="outlined"
                            label={'UF'}
                            allowSubmit
                            select
                            {...field}
                            value={
                              UFMock.find(
                                (uf) =>
                                  uf.Value ===
                                  getValues('documento.ufExpedicao')
                              )?.Key
                            }
                            onChange={(event) => {
                              if (event) {
                                const item = UFMock.filter(
                                  (item) => item.Key === event.target.value
                                )[0];
                                if (item) {
                                  setValue('documento.ufExpedicao', item.Value);
                                }
                              }
                            }}
                            error={Boolean(
                              errors?.documento?.ufExpedicao &&
                              errors.documento.ufExpedicao.message
                            )}
                            helperText={
                              errors.documento?.ufExpedicao
                                ? errors.documento.ufExpedicao?.message
                                : undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={4} xs={8}>
                      <Controller
                        name="documento.orgaoExpedicao"
                        control={control}
                        render={({ field }) => (
                          <SelectSaurus
                            disabled={props.loading}
                            fullWidth
                            autoComplete={'off'}
                            conteudo={TipoDocumentoOrgaoExpedidorMock.sort(
                              (a, b) => a.Value.localeCompare(b.Value)
                            )}
                            label="Órgão Expedidor"
                            placeholder="Ex: SSP"
                            error={Boolean(
                              errors.documento?.orgaoExpedicao &&
                              errors.documento.orgaoExpedicao.message
                            )}
                            helperText={
                              errors.documento?.orgaoExpedicao
                                ? errors.documento.orgaoExpedicao?.message
                                : undefined
                            }
                            {...field}
                            onChange={(e) =>
                              setValue(
                                'documento.orgaoExpedicao',
                                Number(e.target.value)
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="documento.dataExpedicao"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="DATA"
                            disabled={props.loading}
                            fullWidth
                            autoComplete={'off'}
                            label="Data de Expedição"
                            error={Boolean(
                              errors.documento?.dataExpedicao &&
                              errors.documento.dataExpedicao.message
                            )}
                            helperText={
                              errors.documento?.dataExpedicao
                                ? errors.documento.dataExpedicao?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="documento.dataValidade"
                        control={control}
                        render={({ field }) => (
                          <TextFieldSaurus
                            tipo="DATA"
                            disabled={props.loading}
                            fullWidth
                            autoComplete={'off'}
                            label="Data de Validade"
                            error={Boolean(
                              errors.documento?.dataValidade &&
                              errors.documento?.dataValidade.message
                            )}
                            helperText={
                              errors.documento?.dataValidade
                                ? errors.documento?.dataValidade?.message
                                : undefined
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {tipo === EnumCadastroTipo.CLIENTE && (
                  <Grid item xs={12}>
                    <Controller
                      name="representanteId"
                      control={control}
                      render={({ field }) => (
                        <AutocompletePessoas
                          allowSubmit
                          label={'Representante (Opcional)'}
                          isRepresentantePreenhido={
                            (field.value.length ?? 0) > 0
                          }
                          exibirTipo
                          nomeCnpj={EnumNomeCnpj.Nome}
                          {...field}
                          onChange={async (retorno) => {
                            if (!retorno.isString) {
                              const pessoa: PessoaModel = retorno.value;

                              if (pessoa) {
                                const representanteCliente =
                                  new RepresentanteCliente(
                                    pessoa.id,
                                    pessoa.tpCadastro,
                                    pessoa.cpfcnpj,
                                    pessoa.nome,
                                    pessoa.fantasia,
                                    pessoa.status,
                                    pessoa.dtNasc,
                                    pessoa.sexo
                                  );
                                setValue('representante', representanteCliente);
                                setValue(
                                  'representanteId',
                                  representanteCliente.id
                                );
                              } else {
                                setValue('representante', null);
                                setValue('representanteId', '');
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Button style={{ display: 'none' }} type="submit"></Button>
          </form>
        </div>
      </>
    );
  }
);
