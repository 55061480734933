
import { useHistory } from 'react-router-dom';
import { PrivatePageHeader } from 'views/components/headers';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { PdvIcon } from 'views/components/icons/pdv-icon';
import { LogoutIcon, VoltarIcon } from 'views/components/icons';
import { SessaoPDV } from '../../main/menu-principal/components/sessao-pdv/sessao-pdv';
import { EnumMenuPrincipalModelo } from 'model/enums/enum-menu-principal-modelo';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { Box, Button, Typography, Grid } from 'views/design-system';
import classNames from 'classnames';
import { consoleDev } from 'utils/console-dev';
import { MenuPrincipalPDVProps } from './menu-principal-pdv-props';
import { useStyles } from './menu-principal-pdv-styles';
import { ButtonPrivateHeader } from 'views/components/controles';
import { OpcoesListData } from './components/opcoes-list/opcoes-list-data';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { isEqual } from 'lodash';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { EnumMenuPrincipalStatus } from 'model/enums/enum-menu-principal-status';
import { MenuFavoritos } from '../menu-favoritos/menu-favoritos';
import { useThemeQueries } from 'views/theme';
import { PdvRotasMock } from 'data/mocks/pdv-rotas-mock';
import { EnvironmentInfo } from 'config/components/environment-info';
import { useVendaHeaderStore } from 'views/components/headers/venda-header/stores/venda-header-store';

export const MenuPrincipalPDV = (props: MenuPrincipalPDVProps) => {
    // AUX
    const classes = useStyles();
    const { isMobile, xs } = useThemeQueries();
    const { get } = useMenuStorage();
    const { addHandler, removeHandler } = useEventTools();
    const { alterarModo } = useMenuPrincipal();
    const { callTour } = useTourSaurus();
    const { push } = useHistory();
    const [menuAtual, setMenuAtual] = useState(get());
    const { fecharMenu } = useMenuPrincipal();
    const setHideHeader = useVendaHeaderStore(state => state.setHideHeader);

    consoleDev('MenuPrincipalPDV');
    const menuFavoritos = useMemo(() => <MenuFavoritos />, [])

    const leftArea = useMemo(() => {
        return (
            <ButtonPrivateHeader
                icon={<VoltarIcon tipo="PRIVATE_HEADER" />}
                tooltip="Voltar"
                onClick={() => {
                    fecharMenu();
                }}
            ></ButtonPrivateHeader>
        );
    }, [fecharMenu]);

    const retMenuAlterado = useCallback((menu: MenuPrincipalModel) => {
        if (menu.modo !== EnumMenuPrincipalModo.PDV) return;
        setMenuAtual((prev) => {
            const menuEqual = isEqual(prev, menu);
            if (!menuEqual) return menu;
            return prev;
        });
    }, [setMenuAtual]);

    useEffect(() => {
        addHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
        return () => removeHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
    }, [addHandler, removeHandler, retMenuAlterado]);

    const isAberto = menuAtual.situacao === EnumMenuPrincipalStatus.Aberto;

    useEffect(() => {
        if (isAberto) {
            setHideHeader(true);
            callTour(EnumTour.FAVORITARMENU)
        } else {
            setHideHeader(false);
        }
    }, [callTour, isAberto, setHideHeader])

    const content = useMemo(() =>
        <>
            <Grid className={classes.root}>
                <Grid className={classes.header}>
                    <PrivatePageHeader title={'Funções PDV'} leftArea={leftArea} />
                </Grid>
                <Grid container className={classes.contentPerfil}>
                    <SessaoPDV carregando={false} tiraBorderTop modeloAtual={EnumMenuPrincipalModelo.Completo} />
                </Grid>
                <div className={classes.defaultContainer}>
                    <Grid className={classes.containerListCard}>
                        <OpcoesListData />
                    </Grid>
                </div>
                <div className={classes.drawerVersion}>
                    <EnvironmentInfo />
                </div>
                <Box className={classes.btnsFixosContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        rounded
                        fullWidth
                        onClick={async () => {
                            await fecharMenu();
                            alterarModo(EnumMenuPrincipalModo.Retaguarda);
                            push('/dashboard');
                        }}
                        className={classes.btnFixoContent}
                    >
                        <LogoutIcon tipo="BUTTON_PRIMARY" />
                        <Box style={{ marginLeft: 8, lineHeight: "1" }}>
                            <b>Sair do PDV</b>
                            <Typography variant="body2">
                                {!xs && (
                                    <Box className={classNames(classes.maxLine2)} style={{ fontWeight: 500, fontSize: 11 }}>
                                        Acessar Gestão e Cadastros
                                    </Box>
                                )}
                            </Typography>
                        </Box>
                    </Button>
                    <Box className={classes.divisorBtns}></Box>
                    <Button
                        variant='contained'
                        color='primary'
                        rounded
                        fullWidth
                        onClick={async () => {
                            await fecharMenu();
                            push(PdvRotasMock.gerenciarSessao)
                        }}
                        className={classes.btnFixoContent}>
                        <PdvIcon tipo="BUTTON_PRIMARY" />
                        <Box style={{ marginLeft: 8, lineHeight: "1" }}>
                            <b>Meu Caixa</b>
                            <Typography variant="body2">
                                {!xs && (
                                    <Box className={classNames(classes.maxLine2)} style={{ fontWeight: 500, fontSize: 11 }}>
                                        Sangrias, suprimentos e <b>fechamento de caixa</b>
                                    </Box>
                                )}
                            </Typography>
                        </Box>
                    </Button>
                </Box>
            </Grid>
        </>
        , [classes.root, classes.header, classes.contentPerfil, classes.defaultContainer, classes.containerListCard, classes.drawerVersion, classes.btnsFixosContainer, classes.btnFixoContent, classes.maxLine2, classes.divisorBtns, leftArea, xs, fecharMenu, alterarModo, push]);
    return (
        <>
            <Grid className={classes.rootContent} style={{ display: isAberto ? 'block' : 'none' }}>
                <Grid flex flexDirection={isMobile ? 'column' : 'row-reverse'} style={{ height: "100%" }}>
                    {content}
                    {/* AMIGOS DEIXEI O MENU FAVORITOS 
                        AQUI REPLICADO JUSTAMENTE POR CONTA DE LAYOUT.
                        ACEBEI DEIXANDO O MENU COMO ABSOLUTE WIDHT E HEIGHT 100% E PARA APARECER O
                        MENU DE FAVORITOS EU DEVERIA DEIXAR UM BOTTOM REFERENTE AO TAMANHO DO MENU, ACHEI RUIM
                        POIS SE MUDASSE A ALTURA, TERIA QUE MEXER AQUI TBM ENTÂO PREFERI REPLICAR
                        SE TIVER UMA IDEIA DE COMO QUER FAZER, TACALHE-PAU MARCOS. */}
                    {menuFavoritos}
                </Grid>
            </Grid>

        </>
    );
};
