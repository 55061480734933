import { TourStateModel } from 'model/app/tour/tour-model';
import { EnumTour } from 'model/enums/enum-tour';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CallBackProps, STATUS } from 'react-joyride';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { isEmpty } from 'lodash';
import { useMenuPrincipal } from './menu-principal';
import { useThemeQueries } from 'views';
import { useLocation } from 'react-router-dom';
import { useEventTools } from './events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumFluxoTutorial } from 'model/enums/enum-fluxo-tutorial';
import { useGetTutorial } from 'data/api/gestao/tutorial/get-tutorial';
import { sistemaId } from 'config';
import { usePostTutorial } from 'data/api/gestao/tutorial/post-tutorial';
import { usePutTutorial } from 'data/api/gestao/tutorial/put-tutorial';
import { TutorialPostModel, TutorialResModel } from 'model/api/gestao/tutorial/tutorial-post-model';
import { useSessaoAtual } from '../providers';
import { isPlanoFarmaceutico } from 'utils/plano-utils';

interface TriggerTourProps {
    tipo: EnumTour;
    stepIndex: number;
}

interface SkipTourProps {
    tipo: EnumTour;
}

const tourDefaultState = {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
    tipo: EnumTour.VENDA,
    fluxo: EnumFluxoTutorial.PRIMEIRAVENDA
};

export const useTourSaurus = () => {
    const [tourState, setTourState] = useState<TourStateModel>(tourDefaultState);
    const { getRegistro, setRegistro } = useGestaoStorage();
    const { abrirMenu, fecharMenu, isMenuAberto } = useMenuPrincipal();
    const { isMobile } = useThemeQueries();
    const { pathname } = useLocation();
    const { callEvent } = useEventTools();
    const { getTutorial } = useGetTutorial()
    const { postTutorial } = usePostTutorial()
    const { putTutorial } = usePutTutorial()
    const sistemId = sistemaId
    const userId = getRegistro(GestaoStorageKeys.MeuUsuario, false).id
    const { plano } = useSessaoAtual();
    const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])

    const getTours = useCallback(async (tentativa: number = 0) => {
        try {
            const res = await getTutorial(userId, sistemId)
            if (res.erro) throw res.erro;
            return res.resultado?.data
        } catch (e: any) {
            if (tentativa < 2) {
                getTours(tentativa + 1)
            }

        }
    }, [getTutorial, sistemId, userId])

    const putTours = useCallback(async (idTutorial: string, tutorialStatus: TutorialPostModel) => {
        try {
            const res = await putTutorial(idTutorial, tutorialStatus)
            if (res.erro) throw res.erro;
            return res.resultado?.data
        } catch (e: any) { }
    }, [putTutorial])

    const postTours = useCallback(async (tutorialStatus: TutorialPostModel) => {
        try {
            const res = await postTutorial(tutorialStatus)
            if (res.erro) throw res.erro
            return res.resultado?.data
        } catch (e: any) {
        }

    }, [postTutorial])

    const nextStep = useCallback(() => {
        const nextBtn = document.querySelector(
            '[aria-label="Próximo"], [aria-label="Finalizar"]'
        ) as HTMLButtonElement;
        if (nextBtn) {
            nextBtn.click();
        }
    }, []);

    const finishTipo = useCallback(async () => {
        const toursDisponiveis = getRegistro(GestaoStorageKeys.Tutoriais, false)
        const listaDeToursAtualizada: TutorialResModel[] = toursDisponiveis?.filter((tour: TutorialResModel) => tour.tipo !== tourState.tipo)
        const toursList = toursDisponiveis?.find((x: TutorialResModel) => x.json !== 'string' && x.tipo === tourState.tipo)
        const finalizado = new TutorialPostModel(toursList!.id, userId, tourState.stepIndex, true, toursList!.fluxo)
        if (isEmpty(toursList?.tutorialStatus ?? "")) {
            await postTours(finalizado)
        } else {
            await putTours(finalizado.tutorialId, finalizado)
        }
        setRegistro(GestaoStorageKeys.Tutoriais, listaDeToursAtualizada, false)

    }, [getRegistro, postTours, putTours, setRegistro, tourState.stepIndex, tourState.tipo, userId])

    const finishFluxo = useCallback(async () => {
        const toursAtualizado = getRegistro(GestaoStorageKeys.Tutoriais, false)
        let toursList = toursAtualizado?.filter((x: TutorialResModel) => x.json !== 'string' && x.fluxo === tourState.fluxo) as TutorialResModel[]
        let novaLista = toursAtualizado?.filter((x: TutorialResModel) => x.fluxo !== tourState.fluxo) as TutorialResModel[]
        if (toursList) {

            for await (const mesmoFluxo of toursList) {
                const passoFinalizado = new TutorialPostModel(mesmoFluxo.id, userId, tourState.stepIndex, true, mesmoFluxo.fluxo);
                if (isEmpty(mesmoFluxo.tutorialStatus)) {
                    postTours(passoFinalizado);
                } else {
                    putTours(passoFinalizado.tutorialId, passoFinalizado);
                }
                setTourState((prev) => ({ ...prev, run: false }));
            }
            setRegistro(GestaoStorageKeys.Tutoriais, novaLista, false)

        }


    }, [getRegistro, postTours, putTours, setRegistro, tourState.fluxo, tourState.stepIndex, userId]);

    const handleCallback = useCallback(
        async (data: CallBackProps) => {
            const fecharBtn = document.querySelector(
                '[data-action="close"]'
            ) as HTMLButtonElement;
            const spotlight = document.querySelector(
                '.react-joyride__spotlight'
            ) as HTMLDivElement;
            if (fecharBtn) {
                const onClickSkipAll = (ev: Event) => {
                    finishFluxo();
                    ev.target?.removeEventListener('click', onClickSkipAll);
                };
                fecharBtn.style.position = 'absolute';
                fecharBtn.style.right = '';
                fecharBtn.style.top = '';
                fecharBtn.style.bottom = '15px';
                fecharBtn.style.left = '15px';
                fecharBtn.style.marginTop = '15px';
                fecharBtn.style.height = '1rem';
                fecharBtn.textContent = 'Fechar';
                fecharBtn.style.display = 'flex';
                fecharBtn.style.alignItems = 'center';
                fecharBtn.style.justifyContent = 'center';
                fecharBtn.style.boxSizing = 'borderBox';
                fecharBtn?.addEventListener('click', onClickSkipAll);
                const lastIndex = tourState.steps.length - 1;
                if (tourState.steps.length >= 1 && tourState.steps[lastIndex].target === data.step.target && !data.step.spotlightClicks) {
                    fecharBtn.style.display = 'none';
                }
            }

            if (spotlight && data.step.spotlightClicks) {
                const onClickSpotlight = (ev: Event) => {
                    const childElement = document.querySelector(
                        data.step.target.toString()
                    ) as HTMLElement;
                    if (childElement && data.step.spotlightClicks) {
                        childElement.click();
                        childElement.dispatchEvent(new Event('change'));
                    }
                    nextStep();
                    ev.stopPropagation();
                    ev.target?.removeEventListener('click', onClickSpotlight);
                };
                const keyframes = () => {
                    let styleAnimation = document.styleSheets[0].insertRule(
                        '@keyframes destaqueNoBotao { from { opacity: 0; } to { opacity: 1; }}'
                    );
                    return styleAnimation;
                };
                keyframes();
                spotlight.style.animation = 'destaqueNoBotao 1s linear infinite';
                spotlight.style.pointerEvents = 'auto';
                spotlight.style.cursor = 'pointer';
                spotlight.addEventListener('click', onClickSpotlight);

                const btnNext = document.querySelector(
                    '[aria-label="Próximo"], [aria-label="Finalizar"]'
                ) as HTMLButtonElement;
                btnNext.style.display = 'none';
            }

            const FinishedStatus: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

            if (FinishedStatus.includes(data.status)) {
                if (
                    tourState.tipo === EnumTour.EDICAOPDVCONFIG ||
                    tourState.tipo === EnumTour.MESASFORM ||
                    tourState.tipo === EnumTour.IMPRESSORAS ||
                    tourState.tipo === EnumTour.MENUDENAVEGACAO ||
                    tourState.tipo === EnumTour.CONFIGIMPRESSOR
                ) {
                    callEvent(AppEventEnum.TourFinish, { finalizado: true });
                }
                if (tourState.tipo === EnumTour.CONFIGPDV || tourState.tipo === EnumTour.INICIO) {
                    callEvent(AppEventEnum.TourFinish2, { finalizado: true })
                }
                if (tourState.tipo === EnumTour.EDICAOPDVIMPRESSOR) {
                    callEvent(AppEventEnum.TourFinish3, { finalizado: true })
                }
                if (data.status === 'skipped') {
                    await finishFluxo()
                }
                if (data.status === 'finished') {
                    await finishTipo()
                }
                setTourState((prev) => ({ ...prev, run: false }));
            }
            if (data.type === 'tour:start') {
                if (
                    data.step.target.toString().includes('tour-menu') &&
                    !isMenuAberto()
                ) {
                    abrirMenu();
                }
                if (
                    !data.step.target.toString().includes('tour-menu') &&
                    isMenuAberto()
                ) {
                    fecharMenu();
                }
            }
            if (data.action === 'next' && data.type === 'step:after') {
                const getNextTarget =
                    data.index + 1 < tourState.steps.length &&
                    tourState.steps[data.index + 1].target.toString();

                if (!getNextTarget || !document.querySelector(getNextTarget)) {
                    setTourState((prev) => ({ ...prev, stepIndex: prev.stepIndex + 2 }));
                    return;
                }

                if (
                    !isMenuAberto() &&
                    getNextTarget &&
                    getNextTarget.includes('tour-menu') &&
                    isMobile
                ) {
                    await (() =>
                        new Promise<void>((resolve) => {
                            abrirMenu();
                            setTimeout(() => {
                                resolve();
                            }, 500);
                        }))();
                }
                if (
                    isMenuAberto() &&
                    isMobile &&
                    getNextTarget &&
                    !getNextTarget.includes('tour-menu')
                ) {
                    fecharMenu();
                }

                setTourState((prev) => ({ ...prev, stepIndex: prev.stepIndex + 1 }));
            }
            if (data.action === 'prev' && data.type === 'step:after') {
                const getPrevTarget =
                    data.index > 0 &&
                    tourState.steps[data.index - 1] &&
                    tourState.steps[data.index - 1].target.toString();

                if (!getPrevTarget || !document.querySelector(getPrevTarget)) {
                    setTourState((prev) => ({ ...prev, stepIndex: prev.stepIndex - 2 }));
                    return;
                }

                if (
                    !isMenuAberto() &&
                    getPrevTarget &&
                    getPrevTarget.includes('tour-menu') &&
                    isMobile
                ) {
                    await (() =>
                        new Promise<void>((resolve) => {
                            abrirMenu();
                            setTimeout(() => {
                                resolve();
                            }, 500);
                        }))();
                }
                if (
                    isMenuAberto() &&
                    getPrevTarget &&
                    isMobile &&
                    !getPrevTarget.includes('tour-menu')
                ) {
                    fecharMenu();
                }
                setTourState((prev) => ({ ...prev, stepIndex: prev.stepIndex - 1 }));
            }
        },
        [abrirMenu, callEvent, fecharMenu, finishFluxo, finishTipo, isMenuAberto, isMobile, nextStep, tourState.steps, tourState.tipo]
    );

    const triggerTour = useCallback(
        async ({ tipo, stepIndex }: TriggerTourProps) => {
            const tutoriais = getRegistro(GestaoStorageKeys.Tutoriais, false)
            if (isEmpty(tutoriais)) return
            const listTipo = tutoriais?.map((x: TutorialResModel) => x.tipo)
            if (listTipo.includes(tipo)) {
                const tour = tutoriais?.find((x: TutorialResModel) => x.tipo === tipo);
                const steps = JSON.parse(tour?.json ?? "")
                setTourState({
                    run: true,
                    stepIndex: stepIndex,
                    steps: steps,
                    tipo,
                    tourActive: true,
                    fluxo: tour?.fluxo ?? null
                }
                );
            }
        },
        [getRegistro]
    );

    const skipTour = useCallback(
        async ({ tipo }: SkipTourProps) => {
            const tours = getRegistro(GestaoStorageKeys.Tutoriais, false) as TutorialResModel[]
            const listTipo = tours?.map((x: any) => x.tipo)
            if (listTipo.includes(tipo)) {
                finishTipo()
            }
            setTourState((prev) => ({ ...prev, run: false }));
        },
        [finishTipo, getRegistro]
    );

    const callTour = useCallback(
        (tipo: EnumTour) => {
            if (!isFarma) {
                callEvent(AppEventEnum.TourTrigger, { tipo, stepIndex: 0 });
            }
        },
        [callEvent, isFarma]
    );

    useEffect(() => {
        if (tourState.run) {
            skipTour({ tipo: tourState.tipo, });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return {
        setTourState,
        tourState,
        handleCallback,
        triggerTour,
        skipTour,
        finishFluxo,
        nextStep,
        callTour
    };
};
