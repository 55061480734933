import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MenuPrincipal } from './menu-principal';
import { useStyles } from './main-page-styles';
import { useVerificarDispositivo } from 'services/app/hooks/verificar-dispositivo';
import { MenuFavoritos } from './menu-favoritos/menu-favoritos';
import { useMenuStorage } from 'services/app/hooks/menu-storage';
import { MenuPrincipalModel } from 'services/app/hooks/menu-principal/menu-principal-model';
import { isEmpty, isEqual } from 'lodash';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMenuPrincipalModo } from 'model/enums/enum-menu-principal-modo';
import { useLocation } from 'react-router-dom';
import { useMenuPrincipal } from 'services/app/hooks/menu-principal';
import classNames from 'classnames';
import { Tour } from 'views/components/tour/tour-wrapper';
import { useTourSaurus } from 'services/app/hooks/tour-saurus';
import { EnumTour } from 'model/enums/enum-tour';
import { GestaoStorageKeys, useGestaoStorage, useSessaoAtual, useToastSaurus } from 'services/app';
import { useGetTutorial } from 'data/api/gestao/tutorial/get-tutorial';
import { sistemaId } from 'config';
import { TutorialResModel } from 'model/api/gestao/tutorial/tutorial-post-model';
import { isPlanoFarmaceutico } from 'utils/plano-utils';
import { useVerificarAbas } from 'services/app/hooks/verificar-abas';
import { EnumTipoCobranca } from 'model/enums/enum-tipo-combranca';
import { MenuPrincipalPDV } from './menu-principal-pdv/menu-principal-pdv';
import { MovRotasMock } from 'data/mocks/mov-rotas-mock';
import { Box } from 'views/design-system';
import VendaHeader from 'views/components/headers/venda-header/venda-header';


export const MainPage: FC = ({ children }) => {

    const { dispararVerificacao } = useVerificarDispositivo();
    useEffect(() => {
        setTimeout(() => {
            dispararVerificacao();
        }, 7500)
    }, [dispararVerificacao])

    const { plano, deslogar, usuario, meuUsuario, isPlanoAvulso } = useSessaoAtual();
    const isFarma = useMemo(() => isPlanoFarmaceutico(plano?.plano), [plano?.plano])
    const deslogarUser = useCallback(() => {
        deslogar(usuario?.usuarioId || '')
    }, [deslogar, usuario?.usuarioId])

    useVerificarAbas(deslogarUser);

    const { get } = useMenuStorage();
    const { alterarModo } = useMenuPrincipal()
    const [menuAtual, setMenuAtual] = useState(get());
    const modoPDV = menuAtual.modo === EnumMenuPrincipalModo.PDV
    const classes = useStyles();
    const { addHandler, removeHandler } = useEventTools()
    const location = useLocation()
    const { callTour } = useTourSaurus()
    const { getRegistro, setRegistro } = useGestaoStorage()
    const { getTutorial } = useGetTutorial()
    const { callEvent } = useEventTools()
    const { showToast } = useToastSaurus()
    /*
        TIVE QUE COLOCAR O menuAtual NO ARRAY DE DEP DESSES MEMOS.
        ANTES ESTAVA SEM, PORÉM ESTAVA ACARRETANDO EM UM BUG IMPREVISÍVEL.
        DEPOIS DE HORAS DEBUGANDO EU CHEGUEI A CONCLUSÃO QUE NÃO FAZIA SENTIDO,
        AS FUNÇÕES DE SET ESTAVAM SENDO CHAMADAS MAS O COMPONENTE DOS MENUS NÃO RENDERIZAVAM,
        OS ESTADOS NÃO RESPONDIAM, E OS BOTÕES NÃO ABRIAM O MENU FAZENDO O USUÁRIO FICAR
        TRAVADO. ERA APENAS EM SITUAÇÕES ESPECÍFICAS DE TROCA DE TELA DO PDV PRA RETAGUARDA.
        NÃO ACREDITO QUE ISSO IRÁ MACHUCAR MUITO A PERFORMANCE, JÁ QUE OS ESTADOS INTERNOS
        QUE RENDERIZAM O COMPONENTE SÃO CHAMADOS PELO MESMO EVENTO DESTE, PORÉM SE HOUVER
        UMA PIORA NAS MAQUININHAS, PODEMOS TENTAR OUTRA SOLUÇÃO.

        PARA REPRODUZIR O BUG: TIRE O menuAtual DO ARRAY DE DEP, ENTRE NO PDV, ABRA O MENU,
        CLIQUE PARA SAIR DO PDV, IRÁ REDIRECIONAR PARA O DASHBOARD, ENTÃO PELO NAVEGADOR CLIQUE
        PARA VOLTAR PARA URL ANTERIOR, VAI VOLTAR PRO PDV ENTÃO TENTE ABRIR O MENU, ESTAVA FICANDO
        TRAVADO. NO BUILD TAMBÉM ACONTECEU DE AO IR PARA O DASHBOARD, NÃO CONSEGUIR ABRIR O MENU DA
        RETAGUARDA, ENTÃO EU FIQUEI TRAVADO SEM PODER FAZER NADA, ASSIM QUE DESCOBRI O BUG.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const menu = useMemo(() => <MenuPrincipal />, [menuAtual]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const menuPrincipalPDV = useMemo(() => <MenuPrincipalPDV />, [menuAtual])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const menuFavoritos = useMemo(() => <MenuFavoritos renderTour={true} />, [menuAtual])

    const getTutoriais = useCallback(async (tipoDeReferencia: string) => {
        try {
            const res = await getTutorial(tipoDeReferencia, sistemaId)

            if (res.erro) throw res.erro
            return res.resultado?.data as TutorialResModel[]
        } catch (e: any) {

            showToast('error', e.message)
        }
    }, [getTutorial, showToast])

    // const iniciarWizard = useCallback(async () => {
    //     if (getDevice() === EnumDeviceType.CORDOVA_POS) return
    //     const contratoId = getEmpresaSelecionada()?.ContratoId ?? ""
    //     const tutoriaisContrato = await getTutoriais(contratoId)
    //     if (typeof tutoriaisContrato === 'object') {
    //         const wizard = tutoriaisContrato.find((x: TutorialResModel) => x.tipo === EnumTour.WIZARD)

    //         if (wizard && isEmpty(wizard.tutorialStatus)) {
    //             callEvent(AppEventEnum.DialogConfigIniciais, { aberto: true });
    //         }
    //     }
    // }, [callEvent, getEmpresaSelecionada, getTutoriais])

    const carregarTutoriais = useCallback(async () => {
        try {
            let tutorialStorage = getRegistro(GestaoStorageKeys.Tutoriais, false)

            let tutoriais: TutorialResModel[] = []
            let tutoriaisAtualizados = await getTutoriais(meuUsuario?.id ?? "") as TutorialResModel[]

            if (isEmpty(tutorialStorage)) {
                tutoriais = tutoriaisAtualizados
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            }
            else if (!isEqual(tutorialStorage, tutoriaisAtualizados)) {
                tutoriais = tutorialStorage
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            } else {
                tutoriais = tutorialStorage
                setRegistro(GestaoStorageKeys.Tutoriais, tutoriais, false)
                return tutoriais
            }
        } catch (e: any) {
        }
    }, [getRegistro, getTutoriais, meuUsuario?.id, setRegistro])

    const podeExibirWizardPelaData = useCallback(() => {
        const dataDeAdesao = plano?.contratado.dAdesao ?? ''
        const dataPermitidaaPartirDe = "2024-06-21T00:00:00.000000"
        if (dataDeAdesao > dataPermitidaaPartirDe) {
            return true
        }
        return false
    }, [plano?.contratado.dAdesao])

    const pesquisasDeTutoriais = useCallback(async () => {
        const tipoDefaturamento = usuario?.licenca.RepresentanteTipoCobranca
        if ((!isPlanoAvulso() && tipoDefaturamento === EnumTipoCobranca.ClienteFinal && podeExibirWizardPelaData()) || !isFarma) {
            // await iniciarWizard() 
            await carregarTutoriais()
            return
        }
        await carregarTutoriais()
        callEvent(AppEventEnum.FinalizouWizard, { finalizado: true }) //Esse CallEvent serve para finalizar o wizard para seguir com os Tours
    }, [callEvent, carregarTutoriais, isFarma, isPlanoAvulso, podeExibirWizardPelaData, usuario?.licenca.RepresentanteTipoCobranca])

    useEffect(() => {
        pesquisasDeTutoriais()
    }, [pesquisasDeTutoriais])

    useEffect(() => {
        [MovRotasMock.catalogoRota, MovRotasMock.lancamentoAvulsoRota].includes(location.pathname) && setTimeout(() => { callTour(EnumTour.VENDA) }, 500)
    }, [callTour, location.pathname])

    const retMenuAlterado = useCallback((menu: MenuPrincipalModel) => {
        setMenuAtual((prev) => {
            const menuEqual = isEqual(prev, menu);
            if (!menuEqual) return menu;
            return prev;
        });
    }, []);

    useEffect(() => {
        location.pathname.indexOf(MovRotasMock.baseRota) > -1
            ? alterarModo(EnumMenuPrincipalModo.PDV)
            : alterarModo(EnumMenuPrincipalModo.Retaguarda);
    }, [alterarModo, location.pathname, location.search]);

    useEffect(() => {
        addHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
        return () => removeHandler(AppEventEnum.MenuAlterado, retMenuAlterado);
    }, [addHandler, removeHandler, retMenuAlterado]);



    return (
        <Box className={classes.mainContainer}>
            {modoPDV && <VendaHeader />}
            <div className={classNames(classes.containerBody,
                modoPDV ? classes.flexContainer : null,
            )}>
                {!modoPDV && menu}
                {modoPDV && menuPrincipalPDV}
                {modoPDV && menuFavoritos}
                {children}
                <Tour />
            </div>
        </Box>
    );
};
